import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Container, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ImageUploader from '../ImageUploader';
import ImageCarousel from '../Carousel';
import TagInput from '../TagInput';
import { updateDocument } from '../../firebase'; // Make sure this path is correct
import { useSelector } from 'react-redux';


const ContentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const VariableBlogEditor = () => {
  const application = useSelector(state=>state.application);
  const [postId, setPostId] = useState('');
  const [parts, setParts] = useState([]);
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState('');
  const [user, setUser] = useState('');
  const [status,setStatus] = useState('draft');
useEffect(() => {
    if (application.selectedPost) {
      const p = application.selectedPost;
      console.log(p);
      setPostId(p.id || '');
      setTitle(p.title || '');
      setParts(p.parts || []);  // Ensure parts is an array
      setTags(p.tags || []);    // Ensure tags is an array
      setStatus(p.status || 'draft');
    }
  }, [application.selectedPost]);
  useEffect(()=>{
    if(application.currentUser){
        setUser(application.currentUser);
    }
  },[application.currentUser]);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const addPart = (type) => {
    const newPart = { type, content: '', images: [], imageSide: 'left' }; // simplified initialization
    setParts([...parts, newPart]);
  };

  const handleContentChange = (index, field, value) => {
    const updatedParts = parts.map((part, i) => (i === index ? { ...part, [field]: value } : part));
    setParts(updatedParts);
  };

  const handleSave = async () => {
    var uid = user.uid;
    const uniqueId = `${uid}_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
    const xId = postId.length>0?postId:uniqueId
    console.log('postId',xId)
    const newPost = {
      id:xId,
      title,
      parts,
      tags,
      author:uid,
      status: status, // or 'published'
      created: new Date().toISOString(),
      published:false
    };

    try {
      await updateDocument('posts',xId,newPost);
      alert('Post saved successfully!');
      setTitle('');
      setParts([]);
      setTags([]);
    } catch (error) {
      console.error('Error saving the post:', error);
      alert('Failed to save the post.');
    }
  };

  return (
    <Container>
      <Paper sx={{ p: 3 }} elevation={2}>
        <Grid container>
<Grid item xs={12} md={8}>
<Typography variant="h5" gutterBottom>Blog Post Editor</Typography>
</Grid>
<Grid item xs={12} md={4}>
       <Button onClick={handleSave}>Save Post</Button>
</Grid>
        
        <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
       </Grid>
  
<Grid item xs={12}>
        <Button onClick={() => addPart('paragraph')} sx={{ mr: 1 }}>+ Paragraph</Button>
        <Button onClick={() => addPart('textImage')} sx={{ mr: 1 }}>+ Text/Image</Button>
        <Button onClick={() => addPart('carousel')}>+ Image Carousel</Button>
</Grid>
<Grid item xs={12}>
<Paper sx={{ padding: '16px', backgroundColor: '#f7f9fc', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', border: '1px solid #e0e0e0', margin: '20px', '&:hover': { transform: 'scale(1.03)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)' } }}>
          {parts.map((part, index) => (            <ContentBox key={index}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                label={part.type === 'carousel' ? 'Image Carousel' : 'Text'}
                value={part.content}
                onChange={(e) => handleContentChange(index, 'content', e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              {part.type === 'textImage' && (
                <ImageUploader
                  imageUrl={part.imageUrl}
                  onImageUpload={(url) => handleContentChange(index, 'imageUrl', url)}
                  imageSide={part.imageSide}
                  onSideChange={(side) => handleContentChange(index, 'imageSide', side)}
                />
              )}
              {part.type === 'carousel' && (
                <ImageCarousel
                  images={part.images}
                  setImages={(newImages) => handleContentChange(index, 'images', newImages)}
                />
              )}
            </ContentBox>
          ))}
          <TagInput tags={tags} setTags={handleTagsChange} />
        </Paper>
</Grid>
       </Grid>
      
      
   
      </Paper>
    </Container>
  );
};

export default VariableBlogEditor;

import { Box, Grid, Paper, Typography } from "@mui/material"
import { LoadableImage } from "../LoadableImage"


export const DisplayBox = ({ndx,display,value,image})=>{


    return(
        <Paper variant="elevation" elevation={4} key={'displayBox_'+ndx} sx={{height:'auto',minWidth:105,borderRadius:1,display:'block',pt:1,pl:2,pr:2,pb:1,textAlign:'center'}}>
            <Grid container spacing={2}>
                 <Grid item xs={2}>
                     <Box sx={{pt:1.3}}>
                     <LoadableImage imageUrl={image} maxHeight={50} />
                     </Box>
                 </Grid>
                 <Grid item xs={10}>
                     <Box  sx={{display:'flex',flexDirection:'column', justifyContent:'center' , alignItems:'center'}}>
                       <Typography color='secondary' component="h6" fontWeight='bold'  variant='h5'>{display} </Typography>
                       <Typography color='lightgreen' component="p" fontWeight='bold' variant="h5" >{value}</Typography>
                     </Box>
                 </Grid>
              
            </Grid>       
        </Paper>
    )
}
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardActionArea, CardActions, Grid, Stack } from '@mui/material';
import { LoadableImage } from "../LoadableImage"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ARC69Prop } from '../ARC69Prop';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNFT, setSelectedCoop } from '../../applicationSlice';
import { useEffect, useState } from 'react';
import CopyableTypography from '../CopyableTypography';
const CollectionPage =({title,collection,emptyMessage,showUpgrade})=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user,setUser] = useState({});
  const application = useSelector(state=>state.application);
  useEffect(()=>{
    setUser(application.currentUser??{});
  },[application.currentUser])
    return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                <Typography  component='h6' variant='h5' color='white'>{title}</Typography>
                </Grid>
                {collection && collection.length>0?collection.map((item,o) => (
                <Grid item xs={12} sm={6} lg={3}   key={`${item}_${o}`} >
                  <Card sx={{ maxWidth: 600 }}>
                  <LoadableImage imageUrl={item.image} maxHeight={700}/>
              <CardContent>
                <Typography component="p" variant="body1" >
                 Name:{item.assetName}
                </Typography>
                <CopyableTypography text={item.assetId}/>
                
               <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography component={'p'} variant='body2'>Description</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography gutterBottom variant="caption" component="p" color="#393737">
        {item.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
                {item.properties?.length>0?
            
                  <Accordion >
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon color='secondary' />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                   >
                  <Typography component={'p'} variant='body2' >Traits: {item.properties?.length??0}</Typography>
                 </AccordionSummary>
                  <AccordionDetails>
                     <Grid container spacing={1}>
                     {item.properties?item.properties.map((level2,x)=>(
                       <Grid item key={`${level2}_${x}`} >
                        <ARC69Prop prop={level2}/>
                     </Grid>
                    )):''}
                    </Grid>
                 </AccordionDetails>
            </Accordion>
        :''}
        </CardContent>
          <CardActions>
            <Button color='primary' size="small"  onClick={()=>{
              dispatch(setCurrentNFT(item));
              navigate('/view-nft');         
            }}>
                View Larger
            </Button>
            {user.email === 'erin.binford89@gmail.com'?
              <Button color='primary' size="small" onClick={()=>{
                dispatch(setCurrentNFT(item));
                navigate('/edit-nft');
              }} >Edit</Button>
            :'' }
            {showUpgrade?
            <Button color='primary' size="small" onClick={()=>{
              dispatch(setSelectedCoop(item));
              navigate('/create-work-order');
            }}>
              Upgrade</Button>
            :''}
          </CardActions>
    </Card>
</Grid>
    )):<Grid item xs={12}>
        <Typography>{emptyMessage}</Typography>
      </Grid>}
            </Grid>
                

    )
}
export default CollectionPage
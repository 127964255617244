import { useState } from 'react';
import { Button,Box } from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
const ImageUploader = ({ imageUrl, onImageUpload, imageSide, onSideChange }) => {
  const [uploading, setUploading] = useState(false);

  const uploadImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);
    setUploading(true);

    try {
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      onImageUpload(url);
    } catch (error) {
      console.error('Upload failed:', error);
    }
    setUploading(false);
  };

  return (
    <Box>
      <Button variant="contained" component="label">
        <PhotoCamera/>
        <input type="file" hidden onChange={uploadImage} />
      </Button>
      {imageUrl?.length>0? 
                    <img src={imageUrl} alt="Uploaded" style={{ width: 100, marginLeft: 10 }} />
                    : ''}
      {imageUrl?.length>0? 
                    <Button onClick={() => onSideChange(imageSide === 'left' ? 'right' : 'left')}>
                            Toggle Image Side (Current: {imageSide})
                    </Button>
                    :''}
    </Box>
  );
};

export default ImageUploader;

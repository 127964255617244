import {  Box, Container, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { LoadableImage } from "../LoadableImage";
import { Label } from "@mui/icons-material";


const ViewWorkOrder = ()=> 
{
    const application = useSelector((state)=>state.application);

    const [workOrder,setWorkOrder] = useState({});
      
    useEffect(() => {
        setWorkOrder(application.selectedWorkOrder);
    }, [application.selectedWorkOrder]);

   
    return (
        <Container maxWidth='md'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                   <Typography  component='h4' variant='h4'>Construction Request</Typography>
                </Grid>
                <Grid item  xs={12} >
                   <TextField label='Work Order Id' fullWidth value={workOrder.id}/>
                </Grid>
                <Grid item  xs={12}>
                   <TextField label='Requesting Wallet' fullWidth value={workOrder.address}/>
                </Grid>
                <Grid item xs={12}>
                <TextField label="Request Details."
                            multiline
                            rows={4}
                            value={workOrder.description}
                            variant="outlined"
                            fullWidth
    />
</Grid>


<Grid item xs={12} md={4}>
  
<Typography>Coop Exterior</Typography>
    
    <Box  sx={{background:workOrder && workOrder.coopExterior?workOrder.coopExterior:'',height:25,width:25}}>
    <Typography sx={{ml:5}}>{workOrder.coopExterior}
   </Typography>
    </Box>
</Grid>
<Grid item xs={12} md={4}>
<Typography>Coop Door</Typography>
 
   
    <Box  sx={{background:workOrder && workOrder.coopDoor?workOrder.coopDoor:'',height:25,width:25}}>
    <Typography sx={{ml:5}}>{workOrder.coopDoor}</Typography>
    </Box>

</Grid>
<Grid item xs={12} md={4}>
<Typography>Coop Trim</Typography>
    <Box  sx={{background:workOrder && workOrder.coopTrim?workOrder.coopTrim:'',height:25,width:25}}>
  
    <Typography sx={{ml:5}}>{workOrder.coopTrim}</Typography>
    </Box>
</Grid>

            </Grid>
        </Container>
    )
}
export default ViewWorkOrder
import { Box } from "@mui/material";
import { useState,useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
const CustImage = ({ mediaType,src, alt,maxHeight }) => {
    return mediaType==='video/mp4'?<video src={src}/>:<img src={src} alt={alt} style={{  maxWidth : "100%", maxHeight: maxHeight, display:"block" }} />;

  };
  
  export const LoadableImage = ({ mediaType,imageUrl,maxHeight }) => {
    console.log(imageUrl);
    const [loading, setLoading] = useState(true);
    const [imgSrc, setImgSrc] = useState(null);
  
    useEffect(() => {
      if(imageUrl?.length>0){
        setLoading(false);
        
      }
      setImgSrc(imageUrl?.replace('ipfs.io', 'ipfs.algonode.xyz'));
      if(imageUrl?.length>0){
        setLoading(false);
      }
    }, [imageUrl]);
  
    return (
      <Box >
        <RingLoader
          color="yellow"
          loading={loading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        {imgSrc && <CustImage mediaType={mediaType} src={imgSrc} maxHeight={maxHeight} alt="loaded image" />}
      </Box>
    );
  };
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const loadImageWithTimeout =(imageUrl, onSuccess, onFailure)=> {
    const img = new Image();
    img.onload = function() {
      clearTimeout(timeoutId);
      onSuccess(img);
    };
    img.onerror = function() {
      clearTimeout(timeoutId);
      onFailure(img);
    };
    const timeoutId = setTimeout(function() {
      img.src = ''; // Abort image loading
      onFailure(img);
    }, 180000);
  
    img.src = imageUrl;
    return img;
  }
  
import { Button, Container, Grid, IconButton, Typography } from "@mui/material"
import { DisplayBox } from "../DisplayBox";
import { useEffect, useState } from "react";
import algosdk from "algosdk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {PeraWalletConnect} from "@perawallet/connect"
import { disconnectAndBurn, setAddress } from '../../walletSlice';
const peraWallet = new PeraWalletConnect();

const dec = new TextDecoder();
const token = {
    'X-API-Key': process.env.REACT_APP_PURESTAKE_API,
  };
  const algoIcon = 'https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/algorand-algo-logo.png?alt=media&token=356abea8-4a9e-4746-ba3e-a12bdf207077';
  const coopIcon = 'https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/coop.png?alt=media&token=3c3007c4-53a9-4986-bcaa-c1942d41eba6';
  const feathersIcon='https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/Feathers.png?alt=media&token=e20c573f-c6fb-4b53-a5f8-a13b95d0c61e';
const port = "";
      const indexer = `https://mainnet-idx.algonode.cloud/v2`;
      var purestakeURL = `https://mainnet-algorand.api.purestake.io/ps2`;
const algodClient = new algosdk.Algodv2(token,purestakeURL,port);
const indexerClient = new algosdk.Indexer(token,indexer,port);
var purestakeURL = `https://mainnet-algorand.api.purestake.io/ps2`;
const coop = 796425061;
const feathers = 1091143717;
const HoldersWallet = ()=>{
    const navigate = useNavigate();
    const wallet = useSelector(state=>state.wallet);
    const dispatch = useDispatch();
    const [walletAddress,setWalletAddress]=useState('');
    const [algoAmount,setAlgoAmount]=useState(0);
    const [featherAmount,setFeatherAmount]=useState(0);
    const [coopAmount,setCoopAmount]=useState(0);


    useEffect(() => {
      console.log('walletProvider',wallet.walletProvider)
      if(wallet.walletProvider==="Pera" || wallet.walletProvider ===''){
        peraWallet
        .reconnectSession()
        .then((accounts) => {
          peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
  
          if (accounts.length) {
            console.log(`reconnecting session: ${accounts[0]}`);
            dispatch(setAddress(accounts[0]));
          }
        })
        .catch((e) => console.log(e));
      }
      if(wallet?.address.length>1){
        getWallet(wallet.address);
    }else{
        navigate('/');
    }
    
    }, []);


   
    async function getWallet(address) {
        try {
          const response = await indexerClient.lookupAccountByID(address).do();
          const wallet = response.account;
          let cb = wallet.assets.filter(x=>x['asset-id']===coop)[0];
          if(cb){
            console.log('cb',cb)
            let cBal = cb.amount / 1e6;
            console.log('cBal',cBal)

             setCoopAmount(cBal);
          }
          let f = wallet.assets.filter(x=>x['asset-id']===feathers)[0];
          if(f){
              let fBal = f.amount;
              if(fBal){  
                console.log(fBal)
                setFeatherAmount(fBal);
          }
        }
        
          const balance = wallet.amount / 1e6; // Algo amount is in microAlgos, convert to Algos
          setAlgoAmount(balance);
          console.log('Algo balance:', balance);
        } catch (error) {
          console.error('Error retrieving wallet balance:', error.message);
        }
      }

      const handleDisconnectWalletClick=(provider) =>{
        try { 
     console.log('disconnecting wallet',wallet);
              
  
                 peraWallet?.disconnect();
         
                    
            localStorage.removeItem('CLUCKER_USER_WALLET');
            dispatch(disconnectAndBurn());
           } catch (error) {
           alert(error);
           }
     }


    return (
        <Container maxWidth="xs">
           <Grid container spacing={1}>
               <Grid item xs={12}>
                    <Typography fontWeight='bold' textAlign={'center'} component={'h4'} variant={'h4'}   >Wallet</Typography>
               </Grid>
               <Grid item xs={12}> 
      <Button fullWidth variant="outlined" color="secondary" onClick={()=>{
        localStorage.removeItem('CLUCKER_USER_WALLET');
        handleDisconnectWalletClick();
        navigate('/');
      }} >DISCONNECT</Button>

               </Grid>
               <Grid item xs={12}>
                    <DisplayBox image={algoIcon} ndx={0} display={'$ALGO'} value={algoAmount}></DisplayBox>
               </Grid>
               <Grid item  xs={12}>
                    <DisplayBox image={coopIcon} ndx={1} display={'$COOP'} value={coopAmount}></DisplayBox>
               </Grid>
               <Grid item xs={12}>
                   <DisplayBox image={feathersIcon} ndx={2} display={'$FEATHERS'} value={featherAmount}></DisplayBox>
               </Grid>
               <Grid item xs={12}>
                  <Typography fontWeight='bold' textAlign={'center'} variant='h5' component='h5'>Assets</Typography>
               </Grid>
               <Grid item xs={12}>
                <Button onClick={()=>navigate('/my-gen-1')}  size="large" color="secondary" variant="contained" fullWidth>My Gen 1</Button>
               </Grid>
               <Grid item xs={12}>
                <Button onClick={()=>navigate('/my-gen-2')} size="large" color="secondary" variant="contained" fullWidth>My Gen 2</Button>
               </Grid>
               <Grid item xs={12}>
                <Button onClick={()=>navigate('/my-gen-1-reboot')} size="large" color="secondary" variant="contained" fullWidth>My Gen 1 Reboot</Button>
               </Grid>
               <Grid item xs={12}>
                <Button onClick={()=>navigate('/my-coops')} size="large" color="secondary" variant="contained" fullWidth>My Coops</Button>
               </Grid>
               <Grid item xs={12}>
                <Button onClick={()=>navigate('/my-customs')} size="large" color="secondary" variant="contained" fullWidth>MY Customs Cluckers</Button>
               </Grid>
           </Grid>
        </Container>
    )
}
export default HoldersWallet;
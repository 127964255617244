import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';


 function DeletePostPrompt({openPrompt,handleClose}) {
  const [open, setOpen] = useState(false);
useEffect(()=>{
setOpen(openPrompt);
},[openPrompt])


  return (
    
      <Dialog
        fullScreen={true}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete Post?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Are you sure you would like to delete this post? This action is perminant
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>handleClose(true)} color="primary">
            Delete the S.O.B
          </Button>
          <Button onClick={()=>handleClose(false)} color="primary" autoFocus>
            I...I want to keep it.
          </Button>
        </DialogActions>
      </Dialog>
  );
}
export default DeletePostPrompt
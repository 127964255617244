import './App.css';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import LandingPage from './Components/LandingPage';
import { Route, Routes } from 'react-router-dom';
import Banner from './Components/Banner';
import Nav from './Components/Nav';
import Gen1 from './Components/Gen1';
import Gen1Reboot from './Components/Gen1Reboot';
import Gen2 from './Components/Gen2';
import Admin from './Components/Admin';
import SignUpForm from './Components/SignUpForm';
import { useEffect, useState } from 'react';
import { auth, getNFTs, getUser } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setCollections, setCurrentUser } from './applicationSlice';
import HoldersWallet from './Components/HoldersWallet';
import CustomCluckers from './Components/CustomCluckers';
import ViewNFT from './Components/ViewNFT';
import { EditNFT } from './Components/EditNFT';
import MyGen1 from './Components/MyGen1';
import MyGen2 from './Components/MyGen2';
import MyGen1Reboot from './Components/MyGen1Reboot';
import MyCustoms from './Components/MyCustoms';
import SignIn from './Components/SignIn';
import CluckerCoops from './Components/CluckerCoops';
import AlgoAddict420 from './Components/AlgoAddict420';
import MyCoops from './Components/MyCoops';
import CreateWorkOrder from './Components/CreateWorkOrder';
import { About } from './Components/About';
import ViewWorkOrder from './Components/ViewWorkOrder';
import ManageWorkOrders from './Components/ManageWorkOrders';
import CreateNFT from './Components/CreateNFT';
import Gen3 from './Components/Gen3';
import ManageShuffle from './Components/ManageShuffle';
import NFTCollectionViewer from './Components/NFTCollectionViewer';
import Blog from './Components/Blog';
import ManagePosts from './Components/ManageBlogPosts';
import VariableBlogEditor from './Components/VariableBlogEditor';

const theme = createTheme({
  palette: {
    primary: {
      main: '#200101', // Change to your primary color
    },
    secondary: {
      main: '#c71663', // Change to your secondary color
    },
    background:{
      default:'#292929',
      paper:'#74878b'
    },
    text:{
      primary:'#040002',
      secondary:'#393737'
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Change to your desired font
  },
});
function App() {
  const application = useSelector((state)=>state.application);
  const wallet = useSelector((state)=>state.wallet);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  useEffect(()=>{
    setUser(application.currentUser??{});
    return ()=>{}
  },[application.currentUser])
  
    useEffect(() => {
      getNFTs().then((nfts)=>dispatch(setCollections(nfts)));
     
      auth.onAuthStateChanged((auth)=> {
        console.log("Auth state changed",auth);
        if (auth) {
          getUser(auth.uid).then((u=>{
            dispatch(setCurrentUser(u));
            setUser(u);
            
          }));
        }else{
          dispatch(setCurrentUser({}));
          setUser({});
          
        }
      });
    }, []);





  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Nav/>
     <Container fixed>
    <Routes>
   
    <Route path="/" exact element={<LandingPage/>} />
    <Route path="/about" exact element={<About/>} />
    <Route path="/blog" exact element={<Blog/>} />

    <Route path="/gen-1"  element={<NFTCollectionViewer collectionId={'gen-1'}/>} />
    <Route path="/gen-2"  element={<NFTCollectionViewer collectionId={'gen-2'}/>} />
    <Route path="/gen-1-reboot"  element={<NFTCollectionViewer collectionId={'gen-1-reboot'}/>} />
    <Route path="/clucker-coops"  element={<NFTCollectionViewer collectionId={'clucker-coops'}/>} />
    <Route path="/gen-3"  element={<NFTCollectionViewer collectionId={'gen-3'}/>} />
    <Route path="/custom-cluckers"  element={<NFTCollectionViewer collectionId={'custom-cluckers'}/>} />
    <Route path="/sign-up"  element={<SignUpForm/>} />
    <Route path="/sign-in"  element={<SignIn/>} />
    <Route path="/holders-wallet"  element={<HoldersWallet/>} />
    <Route path="/view-nft"  element={<ViewNFT/>} />
    {wallet.address?<Route exact path='/my-gen-1' element={<MyGen1/>}/>:''}
    {wallet.address?<Route exact path='/my-gen-2' element={<MyGen2/>}/>:''}
    {wallet.address?<Route exact path='/my-gen-1-reboot' element={<MyGen1Reboot/>}/>:''}
    {wallet.address?<Route exact path='/my-coops' element={<MyCoops/>}/>:''}
    {wallet.address?<Route exact path='/my-customs' element={<MyCustoms/>}/>:''}
    {wallet.address?<Route exact path='/create-work-order' element={<CreateWorkOrder/>}/>:''}
    {user.role==='admin'?<Route exact path='/view-work-order' element={<ViewWorkOrder/>}/>:''}
    {user.role==='admin'?<Route exact path='/manage-work-orders' element={<ManageWorkOrders/>}/>:''}
    {user.role==='admin'?<Route path="/admin"  element={<Admin/>} />:''}
    {user.role==='admin'?<Route path="/edit-nft"  element={<EditNFT/>} />:''}
    {user.role==='admin'?<Route path="/create-nft"  element={<CreateNFT/>} />:''}
    {user.role==='admin'?<Route path="/manage-shuffle"  element={<ManageShuffle/>} />:''}
    {user.role==='admin'?<Route path="/manage-posts"  element={<ManagePosts/>} />:''}
    {user.role==='admin'?<Route path="/edit-post"  element={<VariableBlogEditor/>} />:''}








    </Routes>
    <AlgoAddict420/>
    </Container>
    </ThemeProvider>
  );
}

export default App;

import { CID } from 'multiformats/cid';
import { sha256 } from 'multiformats/hashes/sha2';
import { create as createDigest } from 'multiformats/hashes/digest';
import algosdk from 'algosdk';

 async function getImageUrl(url, reserveAddr) {
  let chunks = url.split('://');
  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
    // Look for something like: template:ipfs://{ipfscid:1:raw:reserve:sha2-256} and parse into components
    chunks[0] = 'ipfs';
    const cidComponents = chunks[1].split(':');
    if (cidComponents.length !== 5) {
      // give up
      console.log('unknown ipfscid format');
      return url;
    }
    const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents;
    if (cidHash.split('}')[0] !== 'sha2-256') {
      console.log('unsupported hash:', cidHash);
      return url;
    }
    if (cidCodec !== 'raw' && cidCodec !== 'dag-pb') {
      console.log('unsupported codec:', cidCodec);
      return url;
    }
    if (asaField !== 'reserve') {
      console.log('unsupported asa field:', asaField);
      return url;
    }
    let cidCodecCode;
    if (cidCodec === 'raw') {
      cidCodecCode = 0x55;
    } else if (cidCodec === 'dag-pb') {
      cidCodecCode = 0x70;
    }

    // get 32 bytes Uint8Array reserve address - treating it as 32-byte sha2-256 hash
    const addr = algosdk.decodeAddress(reserveAddr);
    const mhdigest = createDigest(sha256.code, addr.publicKey);
    const cid = CID.create(parseInt(cidVersion), cidCodecCode, mhdigest);
    console.log('switching to id:', cid.toString());
    chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/');
    console.log('redirecting to ipfs:', chunks[1]);
    return `ipfs://${chunks[1]}`;
  }
  return url;
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
const cidToReserveURL = (cid) => {
  console.log("CID",cid);
  const decoded = CID.parse(cid)
  const { version } = decoded;
  const url = `template-ipfs://{ipfscid:${version}:dag-pb:reserve:sha2-256}`;
  const reserveAddress = algosdk.encodeAddress(
    Uint8Array.from(Buffer.from(decoded.multihash.digest))
  );
  console.log("Reserve Address",reserveAddress);
  return {
    url,
    reserveAddress,
  };
};
export {base64ToArrayBuffer,getImageUrl,cidToReserveURL}

import { Box, Button, FormControl, Grid, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getSecureConfigByUID } from "../../firebase";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSelector } from "react-redux";
import {updateDocument} from '../../firebase';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
const SecureConfigForm = ()=>{
    const application = useSelector((state)=>state.application);
    const [pinataAPIKey,setPinataAPIKey] = useState('');
    const [beginningKey,setBeginningKey] = useState('');
    const [keysVisible,setKeysVisible] = useState(false);
    const [lastModified,setLastModified] = useState('');
    const [showIframe,setShowIframe] = useState(false);

    useEffect(() => {
      if(application.uid){
        getSecureConfigByUID(application.uid).then((val)=>{
            if(val){
                setPinataAPIKey(val.pinataAPIKey??'');
                setLastModified(val.lastModified);
            }
        });
      }
      return () => {
        
      }
    }, [application.uid]);
 
const toggleKeysVisibility =()=>{
    setKeysVisible(!keysVisible);
}
const handleDeleteApiKeys=()=>{
    setPinataAPIKey('');
    updateDocument("secure-config",application.currentUser.uid,{pinataAPIKey:''}).catch((err)=>alert(err));

}
const handleUpdate =()=>{
    try {
 
    updateDocument("secure-config",application.currentUser.uid,{pinataAPIKey:pinataAPIKey}).then(alert('Successfully Saved')).catch((err)=>alert(err));
} catch (error) {
        console.error(error);
}
};

    return(
<Paper sx={{display:'block', padding:2}}  elevation={4}>
 <Grid container spacing={1}>
    <Grid item >
    <Typography  variant="h5" component="h5">Pinata JWT:</Typography> 
<Typography sx={{pb:1,pl:1}} variant="caption" color="text.secondary" component="h5">Last Modified: {lastModified}</Typography>
    </Grid>
    <Grid item >
    {keysVisible ?
    <Tooltip title="Hide">
      <IconButton sx={{pr:2}} onClick={toggleKeysVisibility}>
            <VisibilityIcon/>
        </IconButton>
        </Tooltip> : 
    <Tooltip title="Show">

        <IconButton sx={{pr:2}} onClick={toggleKeysVisibility}>
            <VisibilityOffIcon/>
        </IconButton>
        </Tooltip>
         }
         <Tooltip title="Save API Key">
           <IconButton  sx={{pr:2}} disabled={beginningKey === pinataAPIKey && beginningKey !==''} onClick={handleUpdate}>
            <SaveIcon/>
           </IconButton>
         </Tooltip>
  
<Tooltip title="Delete Key">
         <IconButton  onClick={handleDeleteApiKeys}>
            <DeleteForeverIcon/>
         </IconButton>
         </Tooltip>
       
    </Grid>
   
    <Grid item xs={11} >
    <TextField fullWidth multiline={keysVisible} type={keysVisible?"text":"password"} label="Pinata API Key" value={pinataAPIKey} onChange={(e)=>setPinataAPIKey(e.target.value)}/>

    </Grid>
<Grid item xs={1}> 
{keysVisible ?
    <Tooltip title="Hide">
      <IconButton sx={{pr:2}} onClick={toggleKeysVisibility}>
            <VisibilityIcon/>
        </IconButton>
        </Tooltip> : 
    <Tooltip title="Show">

        <IconButton sx={{pr:2}} onClick={toggleKeysVisibility}>
            <VisibilityOffIcon/>
        </IconButton>
        </Tooltip>
         }</Grid>    
 
 
 </Grid>
 
        
</Paper>)
}

export default SecureConfigForm;
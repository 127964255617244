import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@mui/material';

function Leaderboard({ weeks }) {
  return (
    <div>
      {weeks.map((week) => (
        <Card raised sx={{ m: 2, marginBottom: 4 }} key={week.week}>
          <CardContent>
            <Typography variant="h5" component="div">
              Week {week.week} Winners
            </Typography>
            <List>
              {week.winners.map((winner, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={`${winner.place === 1 ? '1st' : '2nd'} Place: ${winner.name}`}
                      secondary={`Purchases: ${winner.purchases}`}
                    />
                 
                    <Typography component="span" variant="body2" sx={{ float: 'right', fontWeight: 'bold', mr:5 }}>
                      Prize:
                    </Typography>
                    <ListItemAvatar>
                      <Avatar alt={winner.name} src={winner.imageUrl?.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/')} sx={{ width: 56, height: 56 }} />
                    </ListItemAvatar>
                  </ListItem>
                  {index < week.winners.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default Leaderboard;

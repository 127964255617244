import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardContent, CardHeader, CardMedia, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import {PeraWalletConnect} from "@perawallet/connect"
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import { updateDocument } from '../../firebase';
import { TextEncoder } from 'text-encoding';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReplayIcon from '@mui/icons-material/Replay';
import algosdk from "algosdk";
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AlgoAddict420 from '../AlgoAddict420';
import { LoadableImage } from '../LoadableImage';
import { setCurrentCreatorAddress, setPinningImage } from '../../applicationSlice';
import RingLoader from "react-spinners/RingLoader";
import { setAddress, setLoading } from '../../walletSlice';
import { useNavigate } from 'react-router-dom';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { pinFileToPinata } from '../../Utilities/pinata';
import { cidToReserveURL } from '../../Utilities/nftUtil';



const ARC19_TEMPLATE = "template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}"
const peraWallet = new PeraWalletConnect();

  
export default function CreateNFT(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const application = useSelector((state)=>state.application);
  //  const wallet = useSelector((state)=>state.wallet);
  const [toUpper,setToUpper] = useState(false);
    const [assetName,setAssetName] = useState('');
    const [unitName,setUnitName] = useState('');
    const [description,setDescription] = useState('');
    const [file,setFile] = useState(null);
    const [image,setImage] = useState('');
    const [propertyName,setPropertyName] = useState('');
    const [propertyValue,setPropertyValue] = useState('');
    const [arc19,setArc19] = useState(true);
    const [network,setNetwork] = useState('mainnet');
    const [mimeType,setMimeType] = useState('');
    const [properties,setProperties] = useState([]);
    const [ipfsHash,setIPFSHash] = useState('');
    const [walletAddress,setWalletAddress] = useState('');
    const [pinnedURL,setPinnedURL] = useState('');
    const [reserveAddy,setReserveAddy] = useState('');
    const [pinataAPIKey,setPinataAPIKey] = useState(process.env.REACT_APP_PINATA_JWT_TOKEN);
    const [quantity,setQuantity] = useState(1);
    const [errorMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [cid,setCID] = useState('');
    const [openError,setOpenError] = useState(false);
    const [openSuccess,setOpenSuccess] = useState(false);
    const [freeze,setFreeze] = useState(false);
    const [clawback,setClawback] = useState(false);
    const [defaultFrozen,setDefaultFrozen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [hideInstructions,setHideInstructions] = useState(false);
    const [sortMetadata,setSortMetadata] = useState(false);



    useEffect(() => {
      // Reconnect to the session when the component is mounted
      peraWallet
        .reconnectSession()
        .then((accounts) => {
          if(accounts===0)return;
          peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
  
          if (accounts.length) {
            console.log(`reconnecting session: ${accounts[0]}`);
            setWalletAddress(accounts[0]);
            
          }
        })
        .catch((e) => console.log(e));
    }, []);

useEffect(()=>{
  setUploading(application.pinningImage);
},[application.pinningImage])

const port = "";
const token = ""; 
const indexer = `https://${network}-idx.algonode.cloud`;
const algoNode = `https://${network}-api.algonode.cloud`;
const algodClient = new algosdk.Algodv2( token,algoNode, port);
const indexerClient = new algosdk.Indexer( token,indexer, port);
    

    function handleDisconnectWalletClick() {
      if(peraWallet.isConnected){
        peraWallet.disconnect();
      }
      setWalletAddress('');
   }
  
    useEffect(() => {
        let z = '';
        if(application.uid){
          setToUpper(application.currentUser?.metadataToUpper??false);
          setSortMetadata(application.currentUser?.sortMetadata??false);
           
          }
      return () => { }
    }, [application.uid]);



const handleChange=(e)=>{
setNetwork(e.target.value);
}


const handleError=(error)=>{

  if (typeof error === "object") {
    console.error(error);
  } else if (typeof error === "string") {
     setErrorMessage(error);
  } else {
    console.error(error);

  }

setOpenError(true);
}
  const handleSuccess= async (message)=>{
    let count = application.currentUser.mintCount;
    await updateDocument("users",application.currentUser.uid,{metadataToUpper:toUpper,sortMetadata:sortMetadata, mintCount:count+1},true);
    setErrorMessage('');
    setOpenError(false);
    setSuccessMessage(message);
    setOpenSuccess(true);
    resetMinter();
  }
const handleMint = async ()=>{
  let creatorAddress = walletAddress;
  if(!file){
    handleError("No image selected!");
    return;
  }
  
  if(!assetName){
    handleError("Asset Name was not specified");
    return;
  }

  if(!unitName){
    handleError("Unit Name Required");
    return;

  }
  if(unitName.length>8){
    handleError("Unit Name Cannot be longer that 8 Char");
    return;

  }



  if(!pinnedURL){
    handleError("IPFS Hash is empty!");
      return ;
  }



 try { 

  if(creatorAddress ===''){
    setWalletAddress('');
    if(peraWallet.isConnected()){
      handleError("Creators Address is empty! Please connect wallet");
      handleDisconnectWalletClick();
      return;
    }
  }
if(creatorAddress){
  console.info("Creators Address",creatorAddress);


  const params = await algodClient.getTransactionParams().do();
  const enc = new TextEncoder();
  let imageUrl = `https://ipfs.algonode.xyz/ipfs/${cid}`;
  let props ={};
  for (let ndx = 0; ndx < properties.length; ndx++) {
    const element = properties[ndx];
    if(element.property_value!== undefined && element.property_name!==undefined){
      let pn = toUpper ? element.property_name.toString().trim().toUpperCase():element.property_name.toString().trim();
      let pv = toUpper ? element.property_value.toString().trim().toUpperCase():element.property_value.toString().trim();
          props[pn] = pv;
    }

  }

  const note = {
    standard:"arc69",
    name: assetName,
    creator:creatorAddress,
    description: description,
    image: imageUrl,
    decimals: 0,
    unitName: unitName,
    image_integrity: '',
    image_mimetype: mimeType,
    properties: props
  };
  let encNote = '';
  if(note){
    encNote = enc.encode(JSON.stringify(note));
  }
  console.info("note",note);
  console.info("arc19",arc19);


  const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
    from: creatorAddress,
    note: encNote,
    suggestedParams: params,
    assetName: assetName,
    assetURL: arc19? ARC19_TEMPLATE : imageUrl,
    total: quantity,
    decimals: 0,
    freeze:freeze?creatorAddress:'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ',
    clawback:clawback?creatorAddress:'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ',
    unitName: unitName,
    defaultFrozen: false,
    manager: creatorAddress,
    reserve: reserveAddy,
    
  });
  console.info(txn);
  const singleTxnGroups = [{txn: txn, signers: [creatorAddress]}];
  let txSigned =  await peraWallet.signTransaction([singleTxnGroups]);
  console.info("Signed Transaction",txSigned);
  let txRes = await algodClient.sendRawTransaction(txSigned).do();
  let confirmedTxn = await algosdk.waitForConfirmation(algodClient, txRes.txId, 6);
  console.log(confirmedTxn);
  let res = confirmedTxn['asset-index'];
  handleSuccess(`Minting Successful! AssetId:${res}`);
  handleReset();
}

} catch (err) {
  {
    console.log(err);
    if(err.message){
      handleError(err.message);
    }else{
      handleError(JSON.stringify(err));
    }
  }
}

}


const handleReset=()=>{
  setAssetName('');
  setUnitName('');
  setDescription('');
  setProperties([]);
  setErrorMessage('');
  setFile({});
  setIPFSHash('');
  setImage('');
  setMimeType('');
  setQuantity(1);
  setReserveAddy('');
  setPinnedURL('');
}

const handleDeleteProp = (prop)=>{
  console.log(prop);
  
   properties.splice(properties.indexOf(prop),1);
   const nl = properties.map(i=>i);
   if(sortMetadata){
    nl.sort((a,b)=>{
       const nameA = a.property_name.toUpperCase();
       const nameB = b.property_name.toUpperCase();
       if (nameA < nameB) {
          return -1;
       }
       if (nameA > nameB) {
          return 1;
       }
       return 0;
     })
   }
    setProperties(nl);
}
const handleOnChange = (e)=>{
    const files = e.target.files
    var file = files[0];
    setMimeType(file.type);
    setFile(file);
    if(file) {
        Resizer.imageFileResizer(
            file,
            1000,
            1000,
            'jpeg',
            100,
            0,
            uri => {
                setImage(uri);
                },
            'base64'
        );
    }
}
const handleConnectWallet = ()=>{
  if(peraWallet.isConnected){
peraWallet.disconnect().then(()=>peraWallet.connect().then(accounts=>
  {
    if(accounts===0)return;
          peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
          if (accounts.length) {
            console.log(`reconnecting session: ${accounts[0]}`);
            setWalletAddress(accounts[0]);
          }
  }
 )).then(err=>handleError(err));
}else{
  peraWallet.connect().then(accounts=>
    {
      if(accounts===0)return;
            peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
            if (accounts.length) {
              console.log(`reconnecting session: ${accounts[0]}`);
              setWalletAddress(accounts[0]);
            }
    });
}

}
const addKeyValuePair = ()=>{
 try {
  

  if(propertyName===''){
      handleError('Trait Name cannot be empty');
      return;
  }
  if(propertyValue===''){
    handleError('Trait Value cannot be empty');
      return;
  }
  if(properties.filter(i=>i.property_name===propertyName).length>0){
    handleError('Trait Name Already Exists On Asset. Remove Previous trait to add this one.');
    return;
}
  const keyValuePair = {property_name:toUpper?propertyName?.toString()?.trim()?.toUpperCase():propertyName?.toString()?.trim(),property_value:toUpper?propertyValue?.toString()?.trim()?.toUpperCase():propertyValue?.toString()?.trim()};
  console.log("KeyValuePair",keyValuePair);
  for (let index = 0; index < properties.length; index++) {
    const element = properties[index];
    let l = `${keyValuePair.property_name}${keyValuePair.property_value}`;

    let x = `${toUpper?element.property_name.toString().trim().toUpperCase():element.property_name.toString().trim()}${toUpper?element.property_value?.toString()?.trim()?.toUpperCase():element.property_value?.toString()?.trim()}`;
    if(x===l){
      handleError('PropertyName & PropertyValue Combo already exists');
      return;
    }
  }





  
  var newList = properties.concat(keyValuePair);
  if(sortMetadata){
  newList.sort((a,b)=>{
const nameA = a.property_name.toUpperCase();
const nameB = b.property_name.toUpperCase();
if (nameA < nameB) {
return -1;
}
if (nameA > nameB) {
return 1;
}
return 0;

})
  }
  setProperties(newList);
  setPropertyName('');
  setPropertyValue('');
  setErrorMessage('');
  setOpenError(false);
} catch (error) {
  console.error(error);
}
  }

const handlePinImage = async ()=>{
  dispatch(setPinningImage(true));
  pinImageToNFTStorage().then(()=>dispatch(setPinningImage(false))).catch(err=>{
    setPinningImage(false);
    handleError(err);
  });
}


async function pinImageToNFTStorage() {
  dispatch(setPinningImage(true));
  console.info(`Key: ${pinataAPIKey}`);
try {
  
  if (pinataAPIKey ) {
    if (file) {
      pinFileToPinata(file,pinataAPIKey).then(response => {
          console.log('Pinata response:', response);
          let cid = response.IpfsHash;
          setCID(cid);
          const { url, reserveAddress } = cidToReserveURL(cid);
          console.log('url',url);
          setPinnedURL(url);
          setIPFSHash(url);
          setReserveAddy(reserveAddress);
      }).catch(error => {
          console.error('Error:', error);
      });
  } else {
    handleError('No API key supplied!');
    setUploading(false);
    return false;
    }
  }
  
} catch (error) {
  console.error(error);
  handleError(error);
}
dispatch(setPinningImage(false));
}
function resetMinter(){
  setAssetName('');
  setUnitName('')
  setDescription('');
  setFile(null);
  setCID('');
  setImage('');
  setMimeType('');
  setErrorMessage('');
  setProperties([]);
  setPinnedURL('');
  setClawback(false);
  setDefaultFrozen  (false);
  setFreeze(false);
  setIPFSHash('');
  setPropertyName('');
  setPropertyValue('');
  setReserveAddy('');
}


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
return(
<Container maxWidth="md" sx={{pt:1}}>


    <Paper elevation={2} sx={{padding:2.5}}>

    <Typography variant="h5">NFT Minter</Typography>
    
   {walletAddress? <Typography variant="caotion">Creator Address: {walletAddress?.substring(0,8)}...</Typography>:''}

       <Grid container spacing={2}>
        <Grid item >

        {walletAddress.length>1 ?
           <IconButton onClick={()=>{
            
            localStorage.removeItem('BBB_USER_WALLET');
            handleDisconnectWalletClick();
          }}>
              <LocalFireDepartmentIcon/>
              <Typography variant="body2">Disconnect and Clear Data</Typography>
           </IconButton>
           : 
           <IconButton onClick={handleConnectWallet}>
              <AccountBalanceWalletIcon/>
              <Typography variant="body2">Connect Wallet</Typography>
            </IconButton>}

        </Grid>
        <Grid item >
        <FormControlLabel sx={{mt:1}} control={<Switch color="secondary" checked={arc19} onChange={(e)=>setArc19(!arc19)}/>} label={arc19?'ARC19':'ARC69'}></FormControlLabel>
        </Grid>
        <Grid item  >
        <FormControl sx={{minWidth:150}} >
              <InputLabel id="network-select-label">Network</InputLabel>
              <Select
                labelId="network-select-label"
                id="network-select"
                value={network}
                label="Network"
                onChange={handleChange}>
                <MenuItem value={"testnet"}>Test</MenuItem>
                <MenuItem value={"mainnet"}>Main</MenuItem>
              </Select>
           </FormControl>
      </Grid>


       <Grid item xs={12}>
       <Grid container spacing={2}> 
                    <Grid item xs={12} md={6}>
                      <Stack direction="column">
                          <Button fullWidth disabled={uploading} sx={{mb:1,mr:1}} color="primary" variant="contained" size="small" onClick={()=>{
                              var element = document.getElementById('file');

                              console.log(element);
                              if(element){
                                  element.click();
                              }else{
                                  alert('could not open file browser');
                              }
                         }}>Select Image</Button>
                       
                       <RingLoader  color="yellow"
                         loading={uploading}
                         cssOverride={override}
                         size={50}
                         aria-label="Loading Spinner"
                         data-testid="loader"/>
                        {image && !uploading?  <LoadableImage maxHeigh={200} imageUrl={image}/>:''}
                        <Typography variant='caption' color='secondary'>{mimeType?`MIME Type:${mimeType}`:''}</Typography>
                       
                      </Stack>
                   
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={1} >
                    {file && !pinnedURL ?   <Tooltip title="Pin image to IFPS">
                             <Button fullWidth disabled={uploading} sx={{mb:1,mr:1}} color="primary" variant="contained" size="small" onClick={handlePinImage}>Pin Image</Button>
                            </Tooltip>:''
                           }
                    {pinnedURL.length>0? <Typography variant='body2' color="secondary">Image Pinned!</Typography>:''}
                     {reserveAddy?<Chip color='secondary' label={reserveAddy?`Reserve Address:${reserveAddy}`:''}/>:''}
                     {pinnedURL?<Chip color='secondary' label={pinnedURL?`Pinned Url:${pinnedURL}`:''}/>:''}

                     </Stack>
                     
                    </Grid>
                    <Grid item xs={12} >
            <Grid container spacing={2}>
            
                <Grid item xs={12} >
                    <TextField fullWidth  name="asset-name" label="Asset Name" value={assetName} onChange={((e)=>setAssetName(e.target.value))}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField fullWidth   name="unit-name" label="Unit Name" value={unitName} onChange={((e)=>setUnitName(e.target.value))}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField fullWidth name="quantity" label="Quantity" value={quantity} onChange={((e)=>{
                      try {
                      const value = parseInt(e.target.value, 10);
                        setQuantity(value)
                      } catch (error) {
                        setQuantity(1);
                       setErrorMessage(error); 
                      }
                      
                    })}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth  multiline rows={3} name="description" label="Description" value={description} onChange={((e)=>setDescription(e.target.value))}/>
                </Grid>
             
         
            <Grid item xs={12}>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Advanced Features - CAUTION</Typography>

        </AccordionSummary>
        <AccordionDetails>
        <Stack direction="column">
            <Tooltip title="Freeze allows for the creator to lock the asset in place for one wallet or all wallets. This should only be used in cases that makes sense.">
              
                <FormControlLabel sx={{mt:1}} control={<Checkbox checked={freeze} onChange={(e)=>setFreeze(!freeze)}/>} label="Freeze"></FormControlLabel>
            </Tooltip>
            <Tooltip title="Defualt-Frozen Should be used in the rareest of circumstances">
               <FormControlLabel disabled={!freeze} sx={{mt:1}} control={<Checkbox checked={defaultFrozen} onChange={(e)=>setDefaultFrozen(!defaultFrozen)}/>} label="Default-Frozen"></FormControlLabel>
             </Tooltip>
            <Tooltip title="Clawback allows for the creator to recall the asset without the holding wallet's permission. This should only be used in cases that makes sense.">
               <FormControlLabel sx={{mt:1}} control={<Checkbox checked={clawback} onChange={(e)=>setClawback(!clawback)}/>} label="Clawback"></FormControlLabel>
             </Tooltip>
              </Stack>
        </AccordionDetails>
      </Accordion>
           
            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} >
            <Box sx={{width:'100%'}}>
            <Grid container spacing={1}>
            <Grid item xs={12} sm={6} >
                  <FormControlLabel  label="UPPERCASE" control={ <Checkbox checked={toUpper} onChange={()=>setToUpper(!toUpper)}/>}/>
            </Grid>
            <Grid item xs={12} sm={6} >
               <FormControlLabel label="Sort By Name A-Z" control={ <Checkbox checked={sortMetadata} onChange={()=>setSortMetadata(!sortMetadata)}/>}/>
                  </Grid>
            <Grid item xs={12} sm={4} md={5}>
                <TextField sx={{width:'100%'}} label="Trait Name" value={propertyName} onChange={(e)=>setPropertyName(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={4} md={5}>
                <TextField  sx={{width:'100%'}} label="Trait value" value={propertyValue} onChange={(e)=>setPropertyValue(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
               <Button color='primary' variant='contained' sx={{width:'100%',height:'90%',marginTop:.3}} onClick={addKeyValuePair}>Add</Button>
            </Grid> 
            <Grid item xs={12}>   
            <Paper sx={{padding:1, borderRadius:3}} elevation={6}>
            <Grid container spacing={1}>
           
            {properties? properties.map((o,i)=>(
                <Grid key={i} item xs={12} sm={6} md={4} lg={3} >
                <Paper sx={{width:'auto',borderRadius:2,border:'1px solid black',pl:1,pr:.5,pt:.5,pb:.5 ,display:'flex'}} >
                  <Grid container>
                    <Grid item xs={10}>
                    <Box onClick={()=>{
                setPropertyName(o.property_name);
                setPropertyValue(o.property_value);
              }}>
                <Typography component="p" variant='body2'>{o.property_name}</Typography>

                <Typography component="p" variant="caption" color="text.secondary">{o.property_value}</Typography>
              </Box>
                    </Grid>
                    <Grid item xs={2}>
                    <IconButton onClick={()=>handleDeleteProp(o)}>
                    <DeleteForeverIcon/>
               </IconButton>
                    </Grid>
                  </Grid>
      
        
                </Paper>
                 </Grid> 
                  
               )):[]}
              
            </Grid>
             </Paper>
            </Grid> 
            <Grid item xs={12}>
            <Box sx={{width:'100%'}}>
    <Collapse in={openError}>
  <Alert severity='error'    action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setOpenError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }  sx={{ mb: 2 }}>
    {errorMessage}
  </Alert>

  

  </Collapse>
  <Collapse in={openSuccess}> 
  <Alert severity='success'    action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setOpenSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }  sx={{ mb: 2 }}>
    {successMessage}
  </Alert>
  </Collapse>
 
  </Box>
            </Grid> 
       
                          
                  
              <Grid item xs={12}>
              <Button fullWidth disabled={!walletAddress} onClick={handleMint} sx={{marginTop:.3}} variant='contained' color='primary'>MINT {`${arc19?'ARC19':'ARC69'}`}</Button>
              </Grid>
              <Grid item xs={12}>
                 <AlgoAddict420/>
              </Grid>
            
            </Grid>
            </Box>
            </Grid>   
                  </Grid>
                    
            </Grid> 
            
       
       </Grid>
    </Paper>
<input id="file" type="file" style={{display:'none'}} onChange={handleOnChange}/>
</Container>
)

} 
import {  Box, Button, Grid, Paper, Typography } from "@mui/material";
import { LoadableImage } from "../LoadableImage";
import Leaderboard from "../Leaderboard";
import ShuffleIframe from "../ShuffleIframe";


const LandingPage=()=> {

const banner = 'https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/B6CFE8E2-848F-405C-8C45-12CCF0886C8C.png?alt=media&token=f401b83c-9ac9-42c1-8815-d593c7bc1fda';
const weeklyWinners = [ { week: 1,
                          winners: [
                             { place: 1, name: 'midasa.algo', purchases: 10, prize: 'Doofy Clucker', imageUrl:'ipfs://bafybeigeze74kcytpyujbbmeh6fumdgemt7as3aatqiksjikv3lf4kgu2a' },
                             { place: 2, name: 'shaggybigfoot.algo', purchases: 8, prize: 'No Eat Only Buy - Doofy Orig', imageUrl:'https://ipfs.algonode.xyz/ipfs/bafybeiger4iz25762qbsr2kdjr3pqam2sfonzetcwpeeem7dnqpwd4vdbi' }],
                        },
                        { week: 2,
                           winners: [
                              { place: 1, name: 'battlespace.wompcrew.algo', purchases: 10, prize: 'Billy Big Beak Pet #143', imageUrl:'https://ipfs.algonode.xyz/ipfs/QmTSUTj3u4inQmVkaFpCVZMhMvEY3Rf3nRmckzQniUS4q4' },
                              { place: 2, name: 'dcbasically.gotfucked.algo', purchases: 7, prize: 'Banana Thinker', imageUrl:'https://ipfs.algonode.xyz/ipfs/bafkreiglheuu6xafsi2vfo7xvauouh6z63k3agw27cvxby6rpaxi65rxfy#i' }],
                         },
                         { week: 3,
                           winners: [
                              { place: 1, name: 'dcbasically.gotfucked.algo', purchases: 3, prize: 'Bromo #333', imageUrl:'https://ipfs.algonode.xyz/ipfs/bafybeihlntcfddwgj5yz2jwqesqdgu6bb7rrdd2aabollwwxy3lpe3mudm/333.png' },
                              { place: 2, name: 'aychuleta.algo', purchases: 2, prize: 'Pixelinda #195', imageUrl:'https://ipfs.algonode.xyz/ipfs/bafkreihbmfltddevwksyhfvip2k2nfzhuuowq7tlgegiopio3aus2ea4gm/' }],
                         }];
return (
        <Grid container spacing={1} sx={{pt:2}}>
           <Grid item xs={12}>
                <Paper elevation={4}>
                  <Box component={'img'} src={banner} height={'auto'} width={'100%'}></Box>
                <Box sx={{padding:2}}>
                <Typography variant="h4">Welcome to Algo Cluckers!</Typography>
                 <Typography variant="body1">A fun series of hand drawn Cluckers done by AlgoAddict420 that live on the Algorand blockchain!</Typography>
                </Box>


               </Paper>
               </Grid>
               <Grid item xs={12}> 
                  <Box sx={{ textAlign:'center'}}>
                <Typography variant="h4">ALGO CLUCKERS GEN 3 SHUFFLE IS LIVE!!! </Typography>
                  </Box>
                  <ShuffleIframe />
               </Grid>
               <Grid item xs={12}>
               <Leaderboard weeks={weeklyWinners} />
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ textAlign:'center'}}>
                <Typography variant="h4">CLUCKERS CUSTOM KRAX!!! </Typography>
                <Typography>Made by: Mr.Golem</Typography>
                     <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/QmQ2pZSBpnzHqciZmYg4S8VsLuhj1q5Hg6rxWoBLk1jJZY?optimizer=image&width=1152&quality=100'}/>

                  </Box>
               </Grid>
        
              
</Grid>

             
     
   )
}
export default LandingPage;
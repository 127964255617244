import {  Box, Container, Grid, Paper, Typography } from "@mui/material"
import { LoadableImage } from "../LoadableImage"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { setCurrentNFT } from "../../applicationSlice"
import { ARC69Prop } from "../ARC69Prop"

const ViewNFT = ()=> {
    const application = useSelector(state=>state.application);
    const [nft,setNFT] = useState({}); 
useEffect(()=>{
    setNFT(application.currentNFT);
},[application.currentNFT])
    return (
        <Box sx={{pb:5}}>
            <Paper sx={{padding:5}}>
           <LoadableImage imageUrl={nft.imageUrl} maxHeight={1500}/>
           <Typography fontWeight={'bold'}  component="p" variant="body2" >
                 {nft.assetName}
                </Typography>
                <Typography  variant="caption" component="p" color='primary'>
                      Id: {nft.assetId}
                </Typography>
                <Typography  variant="body2" component="p" color='text.primary'>
                      Description: {nft.description}
                </Typography>
           <Grid container spacing={1}>
            {nft.properties? nft.properties.map((level2,x)=>(
              <Grid item key={`${level2}_${x}`} >
               <ARC69Prop prop={level2}/>
                 </Grid>
                 )):''}</Grid>
          </Paper>
        </Box>
    )
}
export default ViewNFT
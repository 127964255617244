import React, { useState, useEffect } from "react";
import DeletePostPrompt from "../DeletePostPrompt";
import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth, getPosts, getEditor, getEditorPosts, doDeletePost, getAllPosts } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setBlogPosts, setCurrentUser, setPosts, setSelectedPost } from "../../applicationSlice";


const ManagePosts = () => {
  const [myPosts, setMyPosts] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector((state)=>state.application);

  useEffect(() => {
    if(application.posts && application.posts.length>0){
      console.log('posts');
      const d = Array.from(application.posts).map(d=>Object.assign({},d,{
        createdDate:new Date(parseInt(d.createdDate)).toLocaleString("en-us"),
        
        }));
      setMyPosts(d);
    }
   
  }, [application.posts]);

useEffect(()=>{
    getPosts(application.currentUser.uid).then(p=>setMyPosts(p)).catch(err=>console.error(err));
},[])




  const handleEditClick = (post) => {
    dispatch(setSelectedPost(post));
    navigate("/edit-post");

  };
  return (
    <Container maxWidth={"lg"} >
      <DeletePostPrompt openPrompt={promptOpen} 
       handleClose={(i)=>{
        setPromptOpen(false);
      if(i){
        console.log('delete');
        doDeletePost(application.selectedPost,auth.uid);
        getPosts(auth.uid);
      }
      else
      {
      console.log('did nothing')
      }
      }}/>
      <Typography variant="h6" align="center" gutterBottom >
       Active Posts
      </Typography>
      <Grid container  spacing={2}>
      <Grid item>
        <Button onClick={()=>{
            dispatch(setSelectedPost({}));
            navigate('/edit-post');}}>Create New Post</Button>
</Grid>
      <Grid item>
      <div style={{ maxWidth: '100%' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Is Draft</TableCell>
              <TableCell>Published</TableCell>
              <TableCell>Action</TableCell> {/* New column for the edit action */}
            </TableRow>
          </TableHead>
          <TableBody>
            {myPosts.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">{row.isDraft?'true':'false' }</TableCell>
                <TableCell align="right">{row.published ?'true':'false'}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => handleEditClick(row)} variant="outlined" color="primary">
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
      </Grid>

  
          
            
      </Grid>
    </Container>
  );
};

export default ManagePosts;

import { Avatar, Container, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, Paper, Stack, Typography } from "@mui/material"

import React, {  useState } from 'react';
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import {PeraWalletConnect} from "@perawallet/connect"
import { disconnectAndBurn, setAddress, setDisconnect, setLoading, setWalletProvider } from "../../walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const peraWallet = new PeraWalletConnect();
const deflyWallet = new DeflyWalletConnect();
const WalletConnection = ({onClose, open})=>{
  const dispatch = useDispatch();
  const wallet = useSelector((state)=>state.wallet);


  useEffect(() => {
    if(wallet &wallet.disconnect){
    console.log('wallet provider')

      if(wallet?.walletProvider??''){
      handleDisconnectWalletClick(wallet.walletProvider);
      
      }
     }
    return () => {
    }
  }, [wallet.walletProvider,wallet.disconnect]);

  
    const handleClose = () => {
        onClose();
      };
    
      const handleListItemClick = (value) => {
        connectWallet(value).then((val)=>console.log(val)).catch(err=>console.error(err));
        onClose();
      };

  

      const handleDisconnectWalletClick=(provider) =>{
        try { 
     console.log('disconnecting wallet');
              if(provider==='Pera'){
                 peraWallet?.disconnect();
               }else if(provider==='Defly'){
                 deflyWallet?.disconnect();
               }
                    
            localStorage.removeItem('CLUCKER_USER_WALLET');
            dispatch(disconnectAndBurn());
            dispatch(setDisconnect(false));
           } catch (error) {
           alert(error);
           }
     }



   const connectWallet = async (provider) => {
   if(provider==='Pera'){
        if(peraWallet.isConnected){
          await peraWallet.disconnect();
        }
             peraWallet.connect().then(async (accounts) => {
              console.log("connectWallet",accounts);
                localStorage.setItem("CLUCKER_USER_WALLET",accounts[0]);
                peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
                dispatch(setAddress(accounts[0]));
                dispatch(setWalletProvider('Pera'));
             }).catch((error) => {
                 if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
                  console.error("handleVerifyWallet error",error);
                  alert(error);
                  dispatch(setLoading(false));
                }
             });
      } else if(provider ==="Defly"){
        if(deflyWallet.isConnected){
          await deflyWallet.disconnect();
        }
        if(deflyWallet.isConnected)return "Connected";
      deflyWallet
     .connect()
     .then(async(accounts) => {
     
          let address = accounts[0];
          localStorage.setItem("CLUCKER_USER_WALLET",address);
          dispatch(setAddress(address));
          dispatch(setWalletProvider('Defly'));

    })
    .catch((error) => {
      if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
        console.error(error);
        alert(error);
        dispatch(setLoading(false));
      }
    });
  }
  };
    return(
        <Dialog key="dialog" onClose={handleClose} open={open}>
            <DialogTitle>Select a Wallet Provider</DialogTitle>
            <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton onClick={()=>handleListItemClick('Pera')} key='Pera'>
                <ListItemAvatar>
                  <Avatar >
                     <img height="40px" src="https://firebasestorage.googleapis.com/v0/b/billy-big-beak.appspot.com/o/pera.png?alt=media&token=67678a72-d5f5-447a-9749-ea53f90fa702"/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Pera" />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton onClick={()=>handleListItemClick('Defly')} key='Defly'>
                <ListItemAvatar>
                  <Avatar >
                     <img height="40px" src="https://firebasestorage.googleapis.com/v0/b/billy-big-beak.appspot.com/o/Defly.png?alt=media&token=40624ac7-1bea-4230-a848-47eea43399fc"/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Defly" />
              </ListItemButton>
            </ListItem>
     </List>   
</Dialog>
//
    )
}
export default WalletConnection

  
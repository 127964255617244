import axios from 'axios';
export async function pinFileToPinata(file,pinataApiKey) {
    console.log("file:",file);

    const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

    // Prepare the header and form data
    const formData = new FormData();
    formData.append('file', file);
    formData.append("pinataMetadata", JSON.stringify({
        name: file.name  // Optional: Add additional metadata as required
    }));
    formData.append("pinataOptions", JSON.stringify({
        cidVersion: 1
    }));
    const headers = {
        'Authorization': `Bearer ${pinataApiKey}`,
    };
    console.log("formData:",formData)
    try {
        const response = await axios.post(url, formData, { headers });
        return response.data;
    } catch (error) {
        console.error('Failed to upload to Pinata:', error);
        throw new Error('Failed to upload file to Pinata');
    }
}
import { Box, Button, Container, FormControlLabel, Grid, Paper, Switch, TextField, Typography } from "@mui/material"
import ManageAssets from "./ManageAssets"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setAddAssetToCreatorsAssets, setCollections, setCurrentCreatorAssets } from "../../applicationSlice";
import { getNFTCollection,getAssetInformation, getNFTs, getWalletCreatedAssets, updateDocument } from "../../firebase";
import { getImageUrl } from "../../Utilities/nftUtil";
import algosdk from "algosdk";
import { fetchCreatedAssets, setNfts } from "../../walletSlice";
import {indexerClient} from '../../algoClient';
import TagInput from "../TagInput";
import AddressInput from "../AddressInput";
import SecureConfigForm from "../SecureConfigForm";
const dec = new TextDecoder();
const ARC19_TEMPLATE = "template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}"
const ARC19_TEMPLATE2 = "template-ipfs://{ipfscid:1:raw:reserve:sha2-256}"




const Admin = ()=>{
    const dispatch = useDispatch();
    const application = useSelector(state=>state.application);
    const [tags, setTags] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [address,setAddress] = useState('');
    const [busy,setBusy] = useState(false);
    const [done,setDone] = useState(false);
    const [message,setMessage] = useState('');
    const [collectionName,setCollectionName] = useState('');
    const [overwrite,setOverwrite] = useState(false);
    const [syncedList,setSyncedList] = useState([]); 

  useEffect(()=>{
    let newData = [];
   if(application.currentCreatorAssets){
   console.log('made it')
    newData.push(application.currentCreatorAssets?.filter(o=>o.collectionName === collectionName ));
    setSyncedList(newData[0]);
}
},[application.currentCreatorAssets])
const handleTagsChange = (newTags) => {
    console.log('handleTagsChange:',newTags)
    setTags(newTags);
  };
  const handleAddressesChange = (newAddresses) => {
    setAddresses(newAddresses);
  };
 
    const handleSync = async ()=>{
        if(addresses.length===0){
           alert("At Least One Algorand Wallet Address is required!");
           setBusy(false);
           return;
        }
        setBusy(true);
        setMessage('fetching account(s)');
        
        const assetsPromises = addresses.map(address => dispatch(fetchCreatedAssets(address)).unwrap());
        const assetsArrays = await Promise.all(assetsPromises);
        const combinedAssets = assetsArrays.flat();
        console.log("combinedAssets",combinedAssets);

        if(combinedAssets){
            let sList = [];
            console.log(`Created Assets:${combinedAssets.length}`);
            let assetIds = combinedAssets.map(i=>i.index);
           for (let index = 0; index < assetIds.length; index++) {
            try {
                const element = assetIds[index];
                var xAsset = combinedAssets.find(o=>o.index===element);
                if(xAsset.deleted)return {message:'Asset marked as Deleted ingoring'};

                let unitName =xAsset.params["unit-name"]??'';
                console.log('unitName',unitName);
                console.log('tags',tags);

                var un = tags.filter(o => unitName.startsWith(o));
                console.log('items by unitName',un)
                if(un.length===0){
                    console.log('didnt match')
                }else{
                  fetchAssetData(element).then(async (val)=>{
                    if(val){
                        if(val.code==="ERR_BAD_REQUEST"){
                            index=assetIds.length+1;
                            setMessage(val.message);
                            return;
                        }
                        const update = Object.assign({},val,{
                            collectionName:collectionName,
                        });
                        let ix = tags.filter(o => unitName.startsWith(o));
                if(ix?.length>0 ){
                    console.log('matches');
                    updateDocument("nfts",`${update.assetId}`,update,true);
                    sList.push(update);
                    dispatch(setAddAssetToCreatorsAssets(update));
                    setSyncedList(sList);
                }

                       
                        
                       
                    }
                }).catch(err=>{
                    console.log(err);
                    setMessage(`ERROR:${err}`);
                });
                await new Promise(r => setTimeout(r, 500));  
                }
                
        } catch (error) {
            console.log(error);
            setMessage(`ERROR:$error`);
        }   
      }
   }
   setBusy(false);
   setDone(true);
    }

    function fetchTimely(url) {
        return fetch(url).then((response) => {
          return response.text();
        });
      }
      function sanitizeCollectionName(collectionName) {
        return collectionName
          .trim() // Trim white spaces
          .toLowerCase() // Convert to lower case
          .replace(/[^a-z0-9\-_]/g, '') // Remove special characters except hyphens and underscores
          .replace(/\s+/g, '-'); // Replace spaces with hyphens
      }
    async function fetchAssetData(assetID){
    try {
         const assetInfo = await indexerClient.lookupAssetByID(assetID).do();
         var asset = assetInfo.asset;
         
         console.log("asset:",asset);
         let assetName =asset.params?.name??'';
         let unitName =asset.params["unit-name"]??'';
         let url = asset.params.url;
         let description = asset.params.description;
         let reserve = asset.params.reserve;
         let initialIpfs = '';
         let image = '';
         let total = asset.params.total;
         let props = [];
         const isArc19 = asset.params.url == ARC19_TEMPLATE;
         const isArc3 = asset.params.url == ARC19_TEMPLATE2;
         if(asset.params.url === ARC19_TEMPLATE ){
            initialIpfs =await getImageUrl(url,reserve);
            image =initialIpfs.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/');
         }
         else if(isArc3)
         {
                const arc3Url =await getImageUrl(url,reserve);
                let xpath =arc3Url.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/');
               
                  const val =await fetchTimely(xpath);
                  console.log('text',val);
                  let json = JSON.parse(val);
                  image = json.image.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/');;
                  description = json.description;
                  console.log('json.properties',json.properties);
                  if(json.properties){
                           var ps = json.properties;
                           console.log("Properties",ps);
                           props = Object.keys(ps).map(key => Object.assign({},{ 
                              property_name:key.trim().toUpperCase(),
                              property_value:ps[key]?.trim()?.toUpperCase()}
                              ,{}) );
                  
                    }
         }
         else{
                  image =url.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/');
         }
         if(isArc3){
            let parsedAsset = {
                assetId:assetID,
                description,
                assetName,
                unitName,
                image,
                total,
                arcType:'arc3',
                properties:props
              }
              return parsedAsset;
         }else{
            const result = await indexerClient.lookupAssetTransactions(assetID).do();
            var transactions = result.transactions;
            var configurations = transactions.filter(i=>i["tx-type"]==="acfg");
            let notesDecoded = [];
            let last = {};
            let parsedAsset = {
                assetId:assetID,
                description,
                assetName,
                unitName,
                image,
                total,
                arcType:isArc19?'arc19':'arc64'
              }
             configurations.forEach(config => {
                 if(config.note){
                     let noteBase64 =Buffer.from(config.note, 'base64');
                     var note = dec.decode(noteBase64);
                     notesDecoded.push(note);
                     last = note;
                 }
             
             });
             var parsed = JSON.parse(last);
             if(parsed){
                parsedAsset['description'] = parsed.description;
                parsedAsset['arcType']='arc64';
                if(parsed.properties){
                    var ps = parsed.properties;
                    console.log("Properties",ps);
                    props = Object.keys(ps).map(key => Object.assign({},{ 
                       property_name:key.trim().toUpperCase(),
                       property_value:ps[key]?.trim()?.toUpperCase()}
                       ,{}) );
                    parsedAsset['properties'] = props;
                }
           
             }
         
             return parsedAsset;
        }
        
       


   } catch (error) {
          console.error(error); 
   }
    }
    const getNumberFromName = (name) => {
        const match = name.match(/\d+$/); // This regex matches the number at the end of the string
        return match ? parseInt(match[0], 10) : null;
    };
    
    // Sort assets by the numerical part of their name
 
    const handleOptimizations = ()=> {
    try {
    const pageSize = 25; // Define how many NFTs per page
    let currentPage = 0;
    let pageContents = [];
    const pages = [];
  
    const safeCollectionName = sanitizeCollectionName(collectionName);
    console.log('safeCollectionName',safeCollectionName);
    
    const sortedAssets = syncedList.sort((a, b) => {
        return getNumberFromName(a.assetName) - getNumberFromName(b.assetName);
    });
    sortedAssets.forEach((nft, index) => {
    pageContents.push({
      assetId:nft.assetId??'',
      assetName: nft.assetName,
      unitName: nft.unitName,
      description: nft.description,
      imageUrl: nft.image,
      properties: nft.properties??[],
      arcType:nft.arcType??''
    });
    console.log('pageContents',pageContents);

    if ((index + 1) % pageSize === 0 || index === sortedAssets.length - 1) {
        var cp = currentPage++;
        console.log(cp);
      const pageData = {
        id:`${safeCollectionName}-${cp}`,
        page: cp,
        nfts: pageContents,
        nftCollectionId:safeCollectionName,
        count: pageContents.length
      };
      pages.push(pageData);
      pageContents = []; // Reset for next page
    }
  });
    const update = {
        id:safeCollectionName,
        nftCount:sortedAssets.length,
        totalPages:pages.length,
        itemsPerPage:pageSize,
        collectionName,
        creatorsWallets:addresses,
        unitNamePrefixes:tags??[],

    };
    console.log('Collection Info',update);
      // Save each page to Firestore
      updateDocument("nftCollections",`${safeCollectionName}`,update,true);
      console.log('updated collection info');

      for (let index = 0; index < pages.length; index++) {
        const element = pages[index];
        console.log('page:',element);
          updateDocument("nftCollectionPages",`${safeCollectionName}-${index}`,element,true);
      }
    } catch (error) {
            console.error(error);
    }
    }
    const fetchCollection = () =>{
       if(collectionName.length>0){
           getNFTCollection(collectionName).then((nfts)=>{
               dispatch(setCurrentCreatorAssets(nfts));
               setSyncedList(nfts);});

       }

    }
    return (
      
        <Container maxWidth="lg">
          <Paper sx={{display:'block', padding:2}}  elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Box sx={{textAlign:'center'}}>
                      <Typography component="h3" variant="h3">ADMIN</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                    <SecureConfigForm/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AddressInput title={'Creator Wallets to Sync'} addresses={addresses} setAddresses={handleAddressesChange}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:"flex"}}>
                    <TextField fullWidth label="Collection Name" value={collectionName} onChange={(e)=>setCollectionName(e.target.value)}/>
                    <FormControlLabel sx={{ml:.5}}  control={<Switch checked={!overwrite} onChange={()=>setOverwrite(!overwrite)}/>} label={overwrite?"Overwrite Data":"Merge Data"} />
                    </Box>
                    <Typography variant="body1" component="p">{message}</Typography>
                </Grid>
                <Grid item xs={12}>

                    <TagInput title={'UnitName Prefixes'} tags={tags} setTags={handleTagsChange}/>
                </Grid>
                <Grid item xs={12}>
                <Button sx={{ml:.5}} variant="contained" disabled={false}  onClick={handleSync}>Sync Creators Wallet</Button>
                <Button sx={{ml:.5}} variant="contained" disabled={false}  onClick={fetchCollection}>Fetch Collection</Button>

                <Button sx={{ml:.5}} variant="contained"   onClick={handleOptimizations}>Optimize</Button>
                </Grid>
                <Grid item xs={12}>
                    <ManageAssets/>
                </Grid>
           </Grid>
           </Paper>
        </Container>
    )
}
export default Admin
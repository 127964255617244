import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function WhitelistInput({ title, whitelist, setWhitelist }) {
  const [newEntry, setNewEntry] = useState({ Address: '', MaxShuffleAmount: '', Price: '', Shuffled: 0 });

  const handleInputChange = (e, field) => {
    setNewEntry({ ...newEntry, [field]: e.target.value });
  };

  const handleAddRow = () => {
    if (newEntry.Address && !whitelist.some(item => item.Address === newEntry.Address)) {
      setWhitelist([...whitelist, newEntry]);
      setNewEntry({ Address: '', MaxShuffleAmount: '', Price: '', Shuffled: 0 }); // Reset input fields
    }
  };

  const handleDelete = (address) => {
    setWhitelist(whitelist.filter(item => item.Address !== address));
  };

  return (
    <Box width="100%">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Max Shuffle Amount</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Shuffled</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {whitelist.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.Address}</TableCell>
                <TableCell>{row.MaxShuffleAmount}</TableCell>
                <TableCell>{row.Price}</TableCell>
                <TableCell>{row.Shuffled ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(row.Address)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField value={newEntry.Address} onChange={(e) => handleInputChange(e, 'Address')} fullWidth />
              </TableCell>
              <TableCell>
                <TextField value={newEntry.MaxShuffleAmount} onChange={(e) => handleInputChange(e, 'MaxShuffleAmount')} fullWidth />
              </TableCell>
              <TableCell>
                <TextField value={newEntry.Price} onChange={(e) => handleInputChange(e, 'Price')} fullWidth />
              </TableCell>
              <TableCell>
                <TextField value={newEntry.Shuffled}  fullWidth />
              </TableCell>
              <TableCell>
                <Button onClick={handleAddRow} variant="contained" color="primary">Add</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default WhitelistInput;
import { Container } from "@mui/material"
import CollectionPage from "../CollectionPage"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOwnedAssets } from "../../walletSlice";

const MyGen1 = ()=>{
    const dispatch = useDispatch();
    const application = useSelector(state=>state.application);
    const wallet = useSelector(state=>state.wallet);
    const [collection,setCollection] = useState([]);
    useEffect(()=>{
       if(application.collections && wallet.address){
       const fetchData  = async ()=>{
        try{
            var result =  await dispatch(fetchOwnedAssets(wallet.address));
            var assets = result.payload;
            console.log("assets",assets);
            return assets;
    
        }catch(error){
            console.error(error);
        }
   
    } 
     fetchData().then((data)=>{
        let output = [];
        let filter1 =application.collections?.filter(o=>o.unitName.startsWith('AC0') ||
                                                     o.unitName.startsWith('CG1') ||
                                                     o.unitName.startsWith('SRCAC') || 
                                                     o.unitName.startsWith('G1R-SRC') ||
                                                     o.unitName.startsWith('ACG1'));
        console.log('total Gen1 assets',filter1);
        for (let index = 0; index < filter1.length; index++) {
            const element = filter1[index];
            const xr = data.filter(i=>i['asset-id']===element.assetId);
            if(xr.length>0){
            console.log('xr',xr[0]);
                if(xr[0].amount>0){
                    output.push(element);
                }
            }
        }
        setCollection(output);
     }).catch(err=>console.error(err));
   

    }
    },[application.collections,wallet.address])
   

      
    return (
        <Container maxWidth='lg'>
            <CollectionPage title={'My Gen 1'} collection={collection} emptyMessage={'You dont own anything from the Gen 1 collection'} />
        </Container>
    )
}
export default MyGen1
import React, { useState,useEffect } from 'react';
import { Container, Grid, TextField, Typography,Box, Paper, Button } from "@mui/material"
import WhitelistInput from "../WhiteListInput";
import Autocomplete from '@mui/lab/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { updateDocument } from '../../firebase';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ManageShuffle = () =>{
    const navigate = useNavigate();
    const application = useSelector(state=>state.application);
    const [id,setId] = useState("");
    const [shuffleName,setShuffleName] = useState("");
    const [status,setStatus] = useState("");
    const [shufflePrice,setShufflePrice] = useState(0);
    const [whitelist,setWhitelist] = useState([]);
    const [keyPhrase, setKeyphrase] = useState('');
    const [showText, setShowText] = useState(false);
    const statuses = ["Open", "Paused", "SoldOut"];
    const [assetIdForShuffle,setAssetIdForShuffle] = useState(0)
    const [uid,setUID] = useState('');
    useEffect(()=>{
        if(application.uid){
            setUID(application.uid);
        }
      },[application.uid]);
    
    const handleSave=()=>{
        let uniqueId = '';

if(id.length>0){
uniqueId = id;
}else{
     uniqueId = `${uid}_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
}
const shuffleData = {
    Id:uniqueId,
    shuffleName,
    shufflePrice,
    whitelist,
    status
};
        updateDocument("shuffles",uniqueId,shuffleData).then(()=>{
            navigate('/admin');
        }).catch(err=>console.error(err));
    }
    const toggleShowText = () => {
        setShowText(!showText);
    };
    const handlePriceChange = (event) => {
        const newValue = event.target.value;
        // Regular expression to check if the input is an integer (or empty to allow clear)
        if (/^\d*$/.test(newValue)) {
            setShufflePrice(newValue);
        }
    };
    return(
        <Container>
            <Paper elevation={2} sx={{padding:1}}>
            <Grid container spacing={2}>
                <Grid item xs={10} >
                    <Typography variant='h4'>Manage Shuffle</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={handleSave}>Save</Button>
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth id="shuffle-name" label="Shuffle Name" variant="outlined" value={shuffleName} onChange={(e)=>setShuffleName(e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                <TextField
                     fullWidth
                     label="Enter $Algo Price"
                     variant="outlined"
                     value={shufflePrice}
                     onChange={handlePriceChange}
                     type="text"  // Set to text type with manual validation to only allow integers
                 />
                </Grid>
                <Grid item xs={4}>
                <TextField value={assetIdForShuffle} fullWidth id="shuffle-image" label="AssetId For Shuffle Image" variant="outlined" onChange={(e)=>setAssetIdForShuffle(e.target.value)} />

                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                       disablePortal
                       onChange={(e)=>setStatus(e.target.value)}
                       id="status-combo-box"
                       options={statuses}
                       sx={{ width: '100%' }}  // Adjust the width as needed
                       renderInput={(params) => <TextField fullWidth {...params} label="Status" />}
                     />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type='password'
                        label="Enter Keyphrase"
                        variant="outlined"
                      
                        value={keyPhrase}
                        onChange={(e)=>setKeyphrase(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleShowText}
                                        edge="end"
                                    >
                                        {showText ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display:"inline-block"}}>
                        <Typography sx={{pl:1}} >Whitelist Configuration</Typography>
                   <WhitelistInput title={'Whitelist Wallets (Paste Address and hit Enter)'} whitelist={whitelist} setWhitelist={setWhitelist}  />
                   </Box>
                </Grid>
               
            </Grid>
            </Paper>
        </Container>
    )
}
export default ManageShuffle
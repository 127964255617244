import { Container } from "@mui/material"
import CollectionPage from "../CollectionPage"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCreatedAssets, fetchOwnedAssets } from "../../walletSlice";


const MyGen2 = ()=>{
    const dispatch = useDispatch();
    const application = useSelector(state=>state.application);
    const wallet = useSelector(state=>state.wallet);

    const [collection,setCollection] = useState([]);
    
   
            useEffect(()=>{
                if(application.collections && wallet.address){
                const fetchData  = async ()=>{
                 try{
                     var result =  await dispatch(fetchOwnedAssets(wallet.address));
                     var assets = result.payload;
                     console.log("assets",assets);
                     return assets;
             
                 }catch(error){
                     console.error(error);
                 }
             } 
              fetchData().then(async (data)=>{
                 let output = [];
                 if(data===undefined)return [];
                 let filter1 =application.collections?.filter(
                    o=>(o.unitName.startsWith('ACG2') || o.unitName.startsWith('G2AC')) &&  !o.unitName.startsWith('ACG2T') && !o.unitName.startsWith('ACG2-A'));
        
                 console.log('total Gen 2 assets',filter1);
                 for (let index = 0; index < filter1.length; index++) {
                    const element = filter1[index];
                    const xr = data.filter(i=>i['asset-id']===element.assetId);
                        console.log('xr',xr[0]);
        
                    if(xr.length>0){
                        if(xr[0].amount>0){
                            output.push(element);
                        }
                    }
                }
                 setCollection(output);
              }).catch(err=>console.error(err));
            
         
             }
             },[application.collections,wallet.address])
   
   
      
    return (
        <Container maxWidth='lg'>
            <CollectionPage title={'My Gen 2'} collection={collection} emptyMessage={'You dont own anything from the Gen 2 collection'}/>
        </Container>
    )
}
export default MyGen2
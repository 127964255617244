import { configureStore,combineReducers } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import applicationSlice from './applicationSlice'
import walletSlice from './walletSlice'


const reducer = combineReducers(
  {
    application:applicationSlice,
    wallet:walletSlice
  })

const store = configureStore({
                              reducer: reducer,
                              middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(),
                              devTools: process.env.NODE_ENV !== 'production',
                            })

export default store
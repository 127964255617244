import { initializeApp } from 'firebase/app';
import {getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,} from 'firebase/auth'
    import {
      getFirestore,
      query,
      getDocs,
      collection,
      where,
      getDoc,
      doc,
      setDoc,
    } from "firebase/firestore";

    


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId:  process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  
  
  
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const registerWithEmailAndPassword = (name, email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
       const data = {
        uid:userCredential.user.uid,
        email:email,
        displayName:name,
        role:'user',
        createdOn:Date.now()
       };
      var docRef = doc(db,"users",data.uid);
      setDoc(docRef,data).catch(err=>console.log(err));
      return 'Signed Up!';
      })
      .catch((err) => {
        return err;
      });
  };
  const updateDocument=(collection,docId,data,merge)=>{
    try {
      var wrappedData =Object.assign(data,{lastModified:new Date().toUTCString()});
      console.log('wrapped data',wrappedData);
      var docRef = doc(db,collection,docId);
      return setDoc(docRef,wrappedData,{merge:merge});
    } catch (error) {
      return error;
    }

    }
  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const logout = () => {
    signOut(auth);
  };
  const doSignInWithEmailAndPassword = (auth,email, password) =>{
    return signInWithEmailAndPassword(auth,email, password);
  
  }
  async function getUser(uid){
    console.log(uid);
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
  return data;
  }
  
  async function getNFTs(){
    let payload = [];
                const nftsRef = collection(db, 'nfts');
                const q =query(nftsRef,where('assetId','>',0));
             await getDocs(q).then((querySnapshot)=>{
                      querySnapshot.forEach((doc) => {
                        if(doc.exists){
                            var data = doc.data();
                            payload.push(data);
                          }
                      });
              });
            
  
    return payload;
    }

    async function getNFTCollection(collectionName){
      let payload = [];
                  const nftsRef = collection(db, 'nfts');
                  const q =query(nftsRef,where('collectionName','==',collectionName));
               await getDocs(q).then((querySnapshot)=>{
                        querySnapshot.forEach((doc) => {
                          if(doc.exists){
                              var data = doc.data();
                              payload.push(data);
                            }
                        });
                });
              
    
      return payload;
      }

    async function getWorkOrders(){
      let payload = [];
                  const nftsRef = collection(db, 'work-orders');
                  const q =query(nftsRef,where('assetId','>',0));
               await getDocs(q).then((querySnapshot)=>{
                        querySnapshot.forEach((doc) => {
                          if(doc.exists){
                              var data = doc.data();
                              payload.push(data);
                            }
                        });
                });
              
    
      return payload;
      }
      async function getSecureConfigByUID(uid){
        try {
          const docRef = doc(db, "secure-config", uid);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists){
            return docSnap.data();
          }else{
            let doc ={uid,nftStorageApiKey:'',PinataAPISecret:'',lastModified:new Date().toUTCString()};
            updateDocument("secure-config",uid,doc,true)
            return doc;
          }
        } catch (error) {
          return {message:error};
        }
      
      }

      async function getAllPosts() {
   
        let payload = [];
        const posts = collection(db, 'posts');
        const q = query(posts, where('published', '==', 'true'));
        await getDocs(q).then((querySnapshot) => {
          if(!querySnapshot.empty){
              querySnapshot.forEach((doc) => {
                          if (doc.exists) {
                            var data = doc.data();
                            payload.push(data);
                          }
                        });
          }else{
            console.log("No Results")
          }
        });
        return payload;
      }

      const doCreatePost=(post)=>{
        if(!post.id)return;
        console.log(post);
        return db.collection("posts").doc(post.id).set(post).then(()=>{
          console.log('Draft created successfully!');
         }).catch(err=>{
           console.error(err);
           console.error(err);
         });
         
      }
      const doPublishPost = post =>{
        if(!post)return;
        var newDoc = Object.assign({},post, {
            isDraft:false,
            published:true,
            publishedOn:new Date(),
            lastUpdate:new Date()
          });
        return this.firestore.collection("posts").doc(post.id).set(newDoc,{merge:true});
      }
    
     const  doUpdatePost = post => {
        console.log(this.auth.currentUser.uid);
        if (!post || !post.id) return "Document Id was not present.";
        var ref = this.firestore.collection("posts").doc(post.id);
        return ref
          .set(post)
          .then(function() {
            console.log("Post updated");
            console.info("Post updated!",{position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true});
           
          })
          .catch(function(error) {
            console.error("Error updating document: ", error);
            console.error("Rat-patootie!! there was an issue..");
    
          });
      };
    
      async function getEditor(uid){
        console.log(`getEditor(${uid})`);
        const docRef = doc(db, "editors", uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
      return data;
      }
      const doDeletePost = (post,uid)=>{
        if(!post.id)return;
        console.log(uid);
        console.log(post)
        this.firestore
        .collection("posts")
        .doc(post.id)
        .delete().then(()=>{
            
          getPosts(uid);
          console.log('Post deleted!');
        })
        .catch(function(error) {
          console.error("Error removing document: ", error);
        });
      }
    
      async function getPosts(uid) {
        let payload = [];
        const posts = collection(db, 'posts');
        const q = query(posts, where('author', '==', uid));
        await getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              var data = doc.data();
              // Exclude the 'lastSaveDate' property from the data
              delete data.lastSaveDate;
              delete data.lastUpdate;
              delete data.publishedOn;
              payload.push(data);
            }
          });
        });
        return payload;
      }

  export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    doSignInWithEmailAndPassword,
    getUser,
    updateDocument,
    getNFTs,
    getWorkOrders,
    getNFTCollection,
    getSecureConfigByUID,
    getAllPosts,
    getEditor,
    doCreatePost,
    doDeletePost,
    doPublishPost,
    doUpdatePost,
    getPosts
  };
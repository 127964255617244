import { Container, Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getWorkOrders } from "../../firebase";
import { setSelectedWorkOrder } from "../../applicationSlice";
import { useNavigate } from "react-router-dom";

const ManageWorkOrders =()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [workOrders,setWorkOrders] = useState([]);
    useEffect(()=>{
        getWorkOrders().then((res)=>{
        setWorkOrders(res);
        })
    },[])   
   
    return(
       
            <Grid container >
            <Grid item>
            <Typography component={'h5'} variant="h5">Coop Work Orders</Typography>
            </Grid>
                <Grid item>
                <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Work Order Id</TableCell>
            <TableCell >AssetId</TableCell>
            <TableCell >Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrders.map((workOrder) => (
            <TableRow onClick={()=>{
                dispatch(setSelectedWorkOrder(workOrder));
                navigate('/view-work-order');
              }}
              key={workOrder.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{workOrder.id}</TableCell>
              <TableCell >{workOrder.assetId}</TableCell>
              <TableCell >{workOrder.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                </Grid>
            </Grid>
    )
}
export default ManageWorkOrders;
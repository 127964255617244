import React, { useState } from 'react';
import { Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Snackbar } from '@mui/material';
const CopyableTypography = ({ text }) => {
    const [open, setOpen] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
          setOpen(true);
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      };
      
      const handleClose = () => {
        setOpen(false);
      };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', color: '#393737' }}>
        <Typography variant="body2" component="p">
          Id: {text}
        </Typography>
        <Tooltip title="Copy" enterDelay={300}>
          <IconButton onClick={handleCopy} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <>
  <div style={{ display: 'flex', alignItems: 'center', color: '#393737' }}>
    {/* Typography and IconButton here */}
  </div>
  <Snackbar
    open={open}
    autoHideDuration={3000}
    onClose={handleClose}
    message={`${text} copied to clipboard`}
  />
</>
      </div>
    );
  };
  export default CopyableTypography
import React, { useEffect, useState } from 'react';
import { doc, getDoc, collection, query, getDocs } from 'firebase/firestore';
import { Button, Card, Grid, Typography, CardMedia, CardContent, Accordion, AccordionSummary, AccordionDetails, CardActions } from '@mui/material';
import { db } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNFT, setNFTCollectionPages, upsertNFTCollectionPages, upsertNFTCollections } from '../../applicationSlice';
import { LoadableImage } from '../LoadableImage';
import CopyableTypography from '../CopyableTypography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ARC69Prop } from '../ARC69Prop';
import { useNavigate } from 'react-router-dom';
const NFTCollectionViewer = ({ collectionId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [collectionInfo, setCollectionInfo] = useState({});

    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user,setUser] = useState({});
    const application = useSelector(state=>state.application);
    useEffect(()=>{
      setUser(application.currentUser??{});
    },[application.currentUser])


    useEffect(()=>{
        fetchNFTs().catch(err=>console.error(err));
        
},[currentPage,collectionId]);

const fetchCollection = async () => {
    setLoading(true);
    const x = application.nftCollections?.filter(p=>p.id===collectionId);
    if(x){
        setCollectionInfo(x);
    }else{
        const collectionRef = doc(db, "nftCollections", collectionId);
        try {
            const collectionSnap = await getDoc(collectionRef);
            if (collectionSnap.exists()) {
                let data = collectionSnap.data();
                setCollectionInfo(data);
                dispatch(upsertNFTCollections([data]));
            } else {
                console.log("No such document!");
                setCollectionInfo({});
            }
        } catch (error) {
            console.error("Error fetching collection:", error);
        }
    }
   
    setLoading(false);
};
    const fetchNFTs = async () => {
        setLoading(true);
        console.log('currentPage',currentPage);
        const pageId = `${collectionId}-${currentPage}`;
        console.log('pageId',pageId);

        const x = application.nftCollectionPages.filter(p=>p.id===pageId);
        console.log('store data',x);

        if(x.length>0){
            console.log('get data from store',x[0].nfts);
            setNfts(x[0].nfts);
        }else{
            console.log('get data from server');

            const pageRef = doc(db, "nftCollectionPages", pageId);
            try {
                const pageSnap = await getDoc(pageRef);
                if (pageSnap.exists()) {
                    let data = pageSnap.data();
                    setNfts(data.nfts);
                    dispatch(upsertNFTCollectionPages([data]));
                    
                } else {
                    console.log("No such document!");
                    setNfts([]);
                }
            } catch (error) {
                console.error("Error fetching page:", error);
            }
        }
       
        setLoading(false);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div>
            <Typography variant="h4">NFT Collection: {collectionId}</Typography>
            <Grid container spacing={2}>
                {nfts &&nfts.map((item, index) => (
                                   <Grid item xs={12} sm={6} md={3} lg={3}   key={`${item}_${index}`} >
                                   <Card sx={{ maxWidth: 600 }}>
                                   <LoadableImage imageUrl={item.imageUrl} maxHeight={700}/>
                               <CardContent>
                                 <Typography component="p" variant="body1" >
                                  Name:{item.assetName}
                                 </Typography>
                                 <CopyableTypography text={item.assetId}/>
                                 
                                <Accordion>
                       <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1a-content"
                         id="panel1a-header"
                       >
                         <Typography component={'p'} variant='body2'>Description</Typography>
                       </AccordionSummary>
                       <AccordionDetails>
                         <Typography gutterBottom variant="caption" component="p" color="#393737">
                         {item.description}
                         </Typography>
                       </AccordionDetails>
                     </Accordion>
                                 {item.properties?.length>0?
                             
                                   <Accordion >
                                   <AccordionSummary
                                       expandIcon={<ExpandMoreIcon color='secondary' />}
                                       aria-controls="panel1a-content"
                                       id="panel1a-header"
                                    >
                                   <Typography component={'p'} variant='body2' >Traits: {item.properties?.length??0}</Typography>
                                  </AccordionSummary>
                                   <AccordionDetails>
                                      <Grid container spacing={1}>
                                      {item.properties?item.properties.map((level2,x)=>(
                                        <Grid item key={`${level2}_${x}`} >
                                         <ARC69Prop prop={level2}/>
                                      </Grid>
                                     )):''}
                                     </Grid>
                                  </AccordionDetails>
                             </Accordion>
                         :''}
                         </CardContent>
                           <CardActions>
                             <Button color='primary' size="small"  onClick={()=>{
                               dispatch(setCurrentNFT(item));
                               navigate('/view-nft');         
                             }}>
                                 View Larger
                             </Button>
                             {user.email === 'erin.binford89@gmail.com'?
                               <Button color='primary' size="small" onClick={()=>{
                                 dispatch(setCurrentNFT(item));
                                 navigate('/edit-nft');
                               }} >Edit</Button>
                             :'' }
                      
                           </CardActions>
                     </Card>
                 </Grid>
                ))}
            </Grid>
            <Button variant="contained" color="primary" onClick={handlePrevPage} disabled={currentPage===0}> 
                Previous Page
            </Button>
            <Button variant="contained" color="primary" onClick={handleNextPage}>
                Next Page
            </Button>
           
        </div>
    );
};

export default NFTCollectionViewer;

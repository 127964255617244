import React from 'react';
import Box from '@mui/material/Box';

function ShuffleIframe() {
    return (
        <Box sx={{ width: '100%', height: 600, overflow: 'hidden', border: '1px solid #ccc', borderRadius: '4px', boxShadow: 1 }}>
            <iframe
                src="https://algocluckers-shuffle.vercel.app/"
                title="AlgoCluckers Shuffle"
                style={{ width: '100%', height: '100%', border: 'none' }}
                allowFullScreen
            >
                {/* Fallback content for browsers that do not support iframes */}
                Your browser does not support iframes. Please visit the <a href="https://algocluckers-shuffle.vercel.app/">AlgoCluckers Shuffle</a> directly.
            </iframe>
        </Box>
    );
}

export default ShuffleIframe;
import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { db, getAllPosts } from '../../firebase'; // Firebase instance
import { useDispatch, useSelector } from 'react-redux';
import { setBlogPosts } from '../../applicationSlice';

const Blog = () => {
const dispatch = useDispatch();
const application = useSelector(state=>state.application);
    const [posts, setPosts] = useState([]);

  useEffect(() => {
        getAllPosts().then((p=>{
            console.log(p);
            if(p.length>0){
                dispatch(setBlogPosts(p));
            }
          })).catch(err=>alert(err));

  }, []);
  useEffect(()=>{
    if(application.blogPosts?.length>0){
        setPosts(application.blogPosts);
    }
  
  },[application.blogPosts])


  return (
    <Container>
      <Typography variant="h4" gutterBottom>Blog Posts</Typography>
      <Grid container spacing={4}>
        {posts.map(post => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <Paper sx={{ padding: 2, height: '100%', position: 'relative' }}>
              <Typography variant="h6" gutterBottom>{post.title}</Typography>
              <Typography variant="body2" gutterBottom>{post.created}</Typography>
              <Typography variant="body2">{post.tags.join(', ')}</Typography>
              <Button
                variant="contained"
                component={Link}
                to={`/post/${post.id}`}
                sx={{ position: 'absolute', bottom: 16 }}
              >
                Read More
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blog;

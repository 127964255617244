import { Box,  Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';

const ImageButton = ({ imageSrc, hoverImageSrc, label, onClick, width, height }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isHovered, setIsHovered] = useState(false);



  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer'}}>
      <Box sx={{display:'inline-flex'}} component='div'> 
        <img src={!isHovered?imageSrc:hoverImageSrc} alt={label} style={{width:width, height:height}} />
        {label?<Typography component='p' variant='body2'>{label}</Typography>:''}
      </Box>
    </div>
  );
};

export default ImageButton;

import { Alert, Button, Checkbox, Chip, Collapse, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Switch, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import SearchIcon from '@mui/icons-material/Search';
import { getAssetById, updateDocument } from "../../firebase";

import algosdk from "algosdk";
import { useDispatch, useSelector } from "react-redux";
import { borderRadius, Box, height } from "@mui/system";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadableImage } from "../LoadableImage";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import Resizer from "react-image-file-resizer";
import {PeraWalletConnect} from "@perawallet/connect"
import CloseIcon from '@mui/icons-material/Close';
import AlgoAddict420 from "../AlgoAddict420";
import RingLoader from "react-spinners/RingLoader";
import { setCurrentCreatorAddress, setPinningImage } from "../../applicationSlice";
import { cidToReserveURL, getImageUrl } from "../../Utilities/nftUtil";
import { pinFileToPinata } from '../../Utilities/pinata';

const peraWallet = new PeraWalletConnect();
const ARC19_TEMPLATE = "template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}"
const ARC19_TEMPLATE2 = "template-ipfs://{ipfscid:1:raw:reserve:sha2-256}"

//

const dec = new TextDecoder();
const pinataAPIKey = process.env.REACT_APP_PINATA_JWT_TOKEN;


export const EditNFT = ()=>{
  const dispatch = useDispatch();
const application = useSelector((state)=>state.application);
const wallet = useSelector((state)=>state.wallet);


const [message,setMessage] = useState("");
const [network,setNetwork] = useState('mainnet');
const [propertyName,setPropertyName] = useState('');
const [propertyValue,setPropertyValue] = useState('');
const [initialIPFS,setInitialIPFS] = useState('');
const [standard,setStandard] = useState('ARC19');
const [uploading, setUploading] = useState(false);


    const [assetId,setAssetId] = useState('');
    const [template,setTemplate] = useState('');

    const [properties,setProperties] = useState([]);
    const [assetName,setAssetName] = useState('');
    const [unitName, setUnitName] = useState('');
    const [description, setDescription] = useState('');
    const [newIPFS, setNewIPFS] = useState('');
    const [cid,setCID] = useState('');
    const [image, setImage] = useState('');
    const [newImage, setNewImage] = useState('');
    const [errorMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [openError,setOpenError] = useState(false);
    const [openSuccess,setOpenSuccess] = useState(false);
    const [toUpper,setToUpper] = useState(false);
    const [sortMetadata,setSortMetadata] = useState(false);

    const [file,setFile] = useState('');
    const [pinnedURL,setPinnedURL] = useState('');
    const [reserveAddy,setReserveAddy] = useState('');
  
    const [mimeType,setMimeType] = useState('');
    const [creatorAddress,setCreatorAddress] = useState('');

    const port = "";
    const token = ""; 
    const indexer = `https://${network}-idx.algonode.cloud`;
    const algoNode = `https://${network}-api.algonode.cloud`;
    const algodClient = new algosdk.Algodv2(token, algoNode, port);
    const indexerClient = new algosdk.Indexer(token, indexer, port);
      useEffect(()=>{
        setUploading(application.pinningImage);
      },[application.pinningImage]);
      useEffect(()=>{
        if(application.currentNFT){
          var nft = application.currentNFT;
          setAssetId(nft.assetId);
      fetchAssetData(nft.assetId).catch(err=>console.error(err));
        }
      },[application.currentNft]);
      useEffect(() => {
        var address = wallet.address;
        setCreatorAddress(address??'');
        return () => { }
      }, [wallet.address])
     
      useEffect(() => {
        peraWallet
          .reconnectSession()
          .then((accounts) => {
            peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
    
            if (accounts.length) {
              console.log(`reconnecting session: ${accounts[0]}`);
              setCreatorAddress(accounts[0]);
            }
          })
          .catch((e) => console.log(e));
          let z = '';
          if(application.uid){
            setToUpper(application.currentUser?.metadataToUpper??false);
            setSortMetadata(application.currentUser?.sortMetadata??false);
             
            }
      }, []);
  


function resetMinter(){
  setAssetId('');
  setAssetName('');
  setUnitName('')
  setDescription('');
  setFile(null);
  setCID('');
  setImage('');
  setInitialIPFS('');
  setMimeType('');
  setNewIPFS('');
  setNewImage('');
  setErrorMessage('');
  setProperties([]);
  setPinnedURL('');
  setPropertyName('');
  setPropertyValue('');
  setReserveAddy('');
  setTemplate('');
}
     
    function handleDisconnectWalletClick() {
        peraWallet.disconnect();
        setCreatorAddress('');
     }
      function handleConnectWalletClick() {
        peraWallet
          .connect()
          .then((newAccounts) => {
            // Setup the disconnect event listener
            peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
    
            setCreatorAddress(newAccounts[0]);
          })
          .catch((error) => {
            // You MUST handle the reject because once the user closes the modal, peraWallet.connect() promise will be rejected.
            // For the async/await syntax you MUST use try/catch
            if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
              // log the necessary errors
            }
          });
      }
      async function pinImageToNFTStorage() {
        dispatch(setPinningImage(true));
        
      try {
        
        if (pinataAPIKey ) {
          if (file) {
            pinFileToPinata(file,pinataAPIKey).then(response => {
                console.log('Pinata response:', response);
                let cid = response.IpfsHash;
                setCID(cid);
                const { url, reserveAddress } = cidToReserveURL(cid);
                 setPinnedURL(url);
                 setNewIPFS(url);
                 setReserveAddy(reserveAddress);
            }).catch(error => {
                console.error('Error:', error);
            });
        } else {
            alert('No file selected.');
          }
        }
        
      } catch (error) {
        console.error(error);
        handleError(error);
      }
      dispatch(setPinningImage(false));
      }
    async function fetchAssetData(assetID){
        try {
            
     const assetInfo = await indexerClient.lookupAssetByID(assetID).do();
     var asset = assetInfo.asset;
     console.log("asset:",asset);
     setAssetName(asset.params?.name??'');
     setUnitName(asset.params["unit-name"]??'');

     let url = asset.params.url;
     let reserve = asset.params.reserve;
    console.log("URL",url);
    console.log("Reserve",reserve);
    setReserveAddy(reserve);
    if(url === ARC19_TEMPLATE || url ===  ARC19_TEMPLATE2){
      setTemplate(asset.params.url);
      getImageUrl(url,reserve).then(ipfs=>
        {
          console.log('initialIPFS', ipfs);
          setInitialIPFS(ipfs);
          let img =ipfs.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/');
          console.log(img);
        setImage(img);}
        ).catch(err=>console.error(err));
    }else{
      setInitialIPFS(asset.params.url);
      setImage(asset.params.url.replace('ipfs://','https://ipfs.algonode.xyz/ipfs/'))
    }
    console.log("Asset",asset);

    const result = await indexerClient.lookupAssetTransactions(assetID).do();
    var transactions = result.transactions;
    var configurations = transactions.filter(i=>i["tx-type"]==="acfg");
    let notesDecoded = [];
    let last = {};
    configurations.forEach(config => {
        if(config.note){
            let noteBase64 =Buffer.from(config.note, 'base64');
            var note = dec.decode(noteBase64);
            notesDecoded.push(note);
            last = note;
        }
       
    });
    var parsed = JSON.parse(last);
    setDescription(parsed.description??'');
    var ps = parsed.properties;
    console.log("Properties",ps);
    var props = Object.keys(parsed.properties).map(key => Object.assign({},{ 
        property_name:toUpper?key.trim().toUpperCase():key.trim(),
        property_value:toUpper?parsed.properties[key].trim().toUpperCase():parsed.properties[key].trim()},{}) );
        setProperties(props);
    } catch (error) {
           console.error(error); 
    }
    }
    const handleOnChange = (e)=>{
        const files = e.target.files
        var file = files[0];
        setMimeType(file.type);
        setFile(file);
        if(file) {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                'jpeg',
                100,
                0,
                uri => {
                  console.log(uri)
                    setNewImage(uri);
                    },
                'base64'
            );
        }
    }
    const addKeyValuePair = ()=>{
 
        if(propertyName===''){
            handleError('Trait Name cannot be empty');
            return;
        }
        if(propertyValue===''){
          handleError('Trait Value cannot be empty');
            return;
        }
        if(properties.filter(i=>i.property_name===propertyName).length>0){
          handleError('Trait Name Already Exists On Asset. Remove Previous trait to add this one.');
          return;
      }
        const keyValuePair = {property_name:toUpper?propertyName?.toString()?.trim()?.toUpperCase():propertyName?.toString()?.trim(),property_value:toUpper?propertyValue?.toString()?.trim()?.toUpperCase():propertyValue?.toString()?.trim()};
        console.log("KeyValuePair",keyValuePair);
        for (let index = 0; index < properties.length; index++) {
          const element = properties[index];
          let l = `${keyValuePair.property_name}${keyValuePair.property_value}`;

          let x = `${toUpper?element.property_name.toString().trim().toUpperCase():element.property_name.toString().trim()}${toUpper?element.property_value?.toString()?.trim()?.toUpperCase():element.property_value?.toString()?.trim()}`;
          if(x===l){
            handleError('PropertyName & PropertyValue Combo already exists');
            return;
          }
        }

 



        
        var newList = properties.concat(keyValuePair);
        if(sortMetadata){
        newList.sort((a,b)=>{
const nameA = a.property_name.toUpperCase();
const nameB = b.property_name.toUpperCase();
if (nameA < nameB) {
  return -1;
}
if (nameA > nameB) {
  return 1;
}
return 0;

})
        }
        setProperties(newList);
        setPropertyName('');
        setPropertyValue('');
        setErrorMessage('');
        setOpenError(false);
        }
const handleChange=(e)=>{
    setNetwork(e.target.value);
    }
const handleSearch= async ()=>{
  setAssetName('');
  setUnitName('');
  setDescription('');
  setProperties([]);
  setErrorMessage('');
  setFile('');
  setImage('');
  setNewImage('');
  setMessage('');
  setMimeType('');
  setReserveAddy('');
  setNewIPFS('');
    await fetchAssetData(assetId);
}
const handleDeleteProp = (prop)=>{
  console.log(prop);
  
   properties.splice(properties.indexOf(prop),1);
   const nl = properties.map(i=>i);
   if(sortMetadata){
    nl.sort((a,b)=>{
       const nameA = a.property_name.toUpperCase();
       const nameB = b.property_name.toUpperCase();
       if (nameA < nameB) {
          return -1;
       }
       if (nameA > nameB) {
          return 1;
       }
       return 0;
     })
   }
    setProperties(nl);
}
const handleError=(error)=> {
    setErrorMessage(error);
    setOpenError(true);
   }
   

 


 const handleUpdate = async ()=>{
  try{
    if(!assetName){
      handleError("Asset Name was not specified");
      return;
    }
    if(!unitName){
      handleError("Unit Name Required");
      return;
    }
  
  
  
  
    if(creatorAddress ===''){
      setCreatorAddress('');
      if(peraWallet.isConnected()){
        handleError("Creators Address is empty! Please connect wallet");
        handleDisconnectWalletClick();
        return;
      }
    }
  if(creatorAddress){
    console.info("Creators Address",creatorAddress);
    const params = await algodClient.getTransactionParams().do();
    console.info("params",params);

    const enc = new TextEncoder();
    let imageUrl = '';
    if(standard==="ARC19"  ){
      imageUrl =initialIPFS.replace("ipfs://","https://ipfs.algonode.xyz/ipfs/");
    }else if(standard==="ARC69")
    {
      if(newIPFS!==''){
        imageUrl = `https://ipfs.algonode.xyz/ipfs/${cid}`;
      }
    }
    
    console.info("params",imageUrl);
    console.info("properties",properties);

    let props ={};
    for (let ndx = 0; ndx < properties.length; ndx++) {
      const element = properties[ndx];
      let name ='Trait Name';
      if(element.property_name){
        name = toUpper?element.property_name.trim().toUpperCase():element.property_name.trim();
      }
      let val = 'Trait Value';
      if(element.property_value){
        val = toUpper?element.property_value.trim().toUpperCase():element.property_value.trim();
      }
      props[name] = val;
    }
    console.info("props",props);
  
    const note = {
      standard:"arc69",
      name: assetName,
      creator:creatorAddress,
      description: description!==undefined?description.trim():'',
      image: imageUrl,
      decimals: 0,
      unitName: unitName,
      image_integrity: '',
      image_mimetype: mimeType,
      properties: props
    };
    console.info("note",note);
    let encNote = '';
    if(note){
      encNote = enc.encode(JSON.stringify(note));
    }
    console.info("encNote",encNote );
    const txn = algosdk.makeAssetConfigTxnWithSuggestedParams(creatorAddress,encNote,parseInt(assetId),creatorAddress,reserveAddy,undefined,undefined,params,false,undefined);
    const singleTxnGroups = [{txn: txn, signers: [creatorAddress]}];
    let txSigned =  await peraWallet.signTransaction([singleTxnGroups],creatorAddress);
    let txRes = await algodClient.sendRawTransaction(txSigned).do();
    let confirmedTxn = await algosdk.waitForConfirmation(algodClient, txRes.txId, 6);
    console.info(confirmedTxn);
    setErrorMessage('');
    setOpenError(false);
    setSuccessMessage(`Update Complete! TxId:${txRes.txId}`);
    setOpenSuccess(true);
    await updateDocument("users",application.currentUser.uid,{metadataToUpper:toUpper,sortMetadata:sortMetadata},true);
    resetMinter();
    return;
  }
  
 
} catch (err) {
  {
    console.error(err);
    if(err.message){
   handleError(err.message);

    }else{
      handleError(err);

    }
  }
}
  }


  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

return (
    <Container maxWidth='md'>

  <Paper elevation={6} sx={{padding:2.5,mt:2}}>
        <Grid container spacing={1} sx={{mt:1}}>
            <Grid item xs={12}  >
                <Typography component="h5" variant="h5">Update Existing NFT</Typography>
                {creatorAddress? <Typography variant="caotion">Creator Address: {creatorAddress?.substring(0,8)}...</Typography>:''}
            </Grid>
            <Grid item xs={12} sm={4} >
            {creatorAddress.length>1 ?
           <IconButton onClick={()=>{
            
            localStorage.removeItem('BBB_USER_WALLET');
            handleDisconnectWalletClick();
          }}>
              <LocalFireDepartmentIcon/>
              <Typography variant="body2">Disconnect and Clear Data</Typography>
           </IconButton>
           : 
           <IconButton  onClick={handleConnectWalletClick}>
              <AccountBalanceWalletIcon/>
              <Typography variant="body2">Connect Wallet</Typography>
            </IconButton>}




            </Grid>
            <Grid item xs={12} sm={4} >
              <FormControl fullWidth  >
              <InputLabel id="network-select-label">Network</InputLabel>
              <Select
                labelId="network-select-label"
                id="network-select"
                value={network}
                label="Network"
                onChange={handleChange}>
                <MenuItem value={"testnet"}>Test</MenuItem>
                <MenuItem value={"mainnet"}>Main</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} >
              <FormControl fullWidth  >
              <InputLabel id="network-select-label">Standard</InputLabel>
              <Select
                labelId="standard-select-label"
                id="standard-select"
                value={standard}
                label="Network"
                onChange={(e=>setStandard(e.target.value))}>
                <MenuItem value={"ARC3"}>ARC3</MenuItem>
                <MenuItem value={"ARC69"}>ARC69</MenuItem>
                <MenuItem value={"ARC19"}>ARC19</MenuItem>

              </Select>
              </FormControl>
            </Grid>

        
         
            <Grid item xs={12}>
              <Typography sx={{pt:1}} component="p" variant="body1">Search for an NFT by AssetId</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField fullWidth  label="AssetID" value={assetId} onChange={(e)=>setAssetId(e.target.value)}/>
               
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button color="primary" fullWidth variant="contained"  sx={{height:'100%'}} onClick={handleSearch}>
                    Search
                </Button>
            </Grid>
           
             
             
                <Grid item xs={12}>
               {image?   
               <span> 
                <Typography variant="body2">Existing NFT</Typography>
                   <img src={image} width={200} height={200} /></span>
               :''}
              </Grid>
              <Grid item xs={12} sm={6}>
                    <TextField fullWidth name="asset-name" label="Asset Name" value={assetName} disabled/>
              </Grid>
              <Grid item xs={12} sm={6}>
                    <TextField fullWidth name="unit-name" label="Unit Name" value={unitName} disabled/>
              </Grid>
              <Grid item xs={12} >
                    <TextField fullWidth  multiline rows={3} name="description" label="Description" value={description} onChange={((e)=>setDescription(e.target.value))}/>
              </Grid>
              {standard==="ARC19"?
              <Grid item xs={12} sm={6} md={3} >
       <Typography variant="body1">Image To Upload</Typography> 
       <Button fullWidth sx={{mb:1,mr:1}} color="primary" variant="contained" size="small" onClick={()=>{
                            var element = document.getElementById('file');
                            if(element){
                                element.click()
                            }else{
                                handleError('could not open file browser');
                            }
                       }}>Select Image</Button>
                
                 
                        
              </Grid>:''}
              <Grid item xs={12}>
                 {newImage && !uploading? <LoadableImage imageUrl={newImage} maxHeight={200} />:''}
              </Grid>
       
       <Grid item xs={12}>
          <Stack direction="column" spacing={1} >
             <Typography>{mimeType?`MIME Type:${mimeType}`:''}</Typography>
                     {newIPFS?<Chip color='secondary' label={`ipfs://${newIPFS}`}/>:''}
                     {reserveAddy?<Chip color='secondary' label={reserveAddy?`Reserve Address:${reserveAddy}`:''}/>:''}
                     {pinnedURL?<Chip color='secondary' label={pinnedURL?`Pinned Url:${pinnedURL}`:''}/>:''}

                     </Stack>
       </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} >
                  <FormControlLabel
  label="UPPERCASE"
  control={ <Checkbox checked={toUpper} onChange={()=>setToUpper(!toUpper)}/>}/>
                  </Grid>
                  <Grid item xs={6} >
                  <FormControlLabel
  label="Sort By Name A-Z"
  control={ <Checkbox checked={sortMetadata} onChange={()=>setSortMetadata(!sortMetadata)}/>}/>
                  </Grid>
        <Grid item xs={12} sm={5} md={4}>
                <TextField sx={{width:'100%'}} label="Trait Name" value={propertyName} onChange={(e)=>setPropertyName(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
                <TextField  sx={{width:'100%'}} label="Trait value" value={propertyValue} onChange={(e)=>setPropertyValue(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2} md={4}>
               <Button color="primary" variant='contained' sx={{width:'100%',height:'90%',marginTop:.3}} onClick={addKeyValuePair}>Add</Button>
            </Grid> 
            {properties? properties.map((o,i)=>(
                <Grid key={i} item xs={12} sm={6} md={4} lg={3} >
                <Paper sx={{width:'auto',borderRadius:2,border:'1px solid black',pl:1,pr:.5,pt:.5,pb:.5 ,display:'flex'}} >
                  <Grid container>
                    <Grid item xs={10}>
                    <Box onClick={()=>{
                setPropertyName(o.property_name);
                setPropertyValue(o.property_value);
              }}>
                <Typography component="p" variant='body2'>{o.property_name}</Typography>

                <Typography component="p" variant="caption" color="text.secondary">{o.property_value}</Typography>
              </Box>
                    </Grid>
                    <Grid item xs={2}>
                    <IconButton onClick={()=>handleDeleteProp(o)}>
                    <DeleteForeverIcon/>
               </IconButton>
                    </Grid>
                  </Grid>
      
        
                </Paper>
                 </Grid> 
                  
               )):[]}
               </Grid>
          
             </Grid>
             <Grid item xs={12}>
            <Collapse in={openError}>
  <Alert severity='error'    action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setOpenError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }  sx={{ mb: 2 }}>
    {errorMessage}
  </Alert>

  

  </Collapse>
  <Collapse in={openSuccess}> 
  <Alert severity='success'    action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setOpenSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }  sx={{ mb: 2 }}>
    {successMessage}
  </Alert>
  </Collapse>
            </Grid>
            {file && newIPFS === '' ?
                <Grid item xs={12}>
                     <Button fullWidth   color="primary" variant="contained"  onClick={pinImageToNFTStorage} >Pin Image</Button>
                </Grid>
               :newIPFS?"Image Pinned!":''}

            
         <Grid item xs={12} >
            <Button fullWidth color="primary" variant="contained"  onClick={handleUpdate}>{`Update ${standard} NFT`}</Button>
        </Grid>
    
        <Grid item xs={12}>
        <AlgoAddict420/>
        </Grid>
        </Grid>
        </Paper>
        <input id="file" type="file" style={{display:'none'}} onChange={handleOnChange}/>
    </Container>
)
}

import {useEffect, useState}from 'react'; 
import { useDispatch, useSelector } from "react-redux"
import { Button, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {  useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

export default function ManageAssets ()
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [localAssets,setLocalAssets]=useState([]);

    const application = useSelector((state)=>state.application);
    useEffect(() => {
      setLocalAssets(application.currentCreatorAssets??[]);
      return () => {
        
      }
    }, [application.currentCreatorAssets])

    return (
        <Box sx={{border:'2px solid black',borderRadius:4}}>
       <List >
        {localAssets?localAssets.map(l=> 
        <ListItem   key={l.assetId} secondaryAction={
            <IconButton edge="end" aria-label="edit" onClick={()=>{
               // dispatch(setSelectedAsset(l));
                navigate('/edit-nft');
                }}>
              <EditIcon />
            </IconButton>
          }>
            <ListItemText primary= {l.assetName} secondary={`Total Minted:${l.total}`}/>
        </ListItem>
        ):<ListItemText primary="No Assets.."/>}
       
       </List>
       </Box>
             )
}

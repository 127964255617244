import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, FormControlLabel, FormGroup, Tooltip, useMediaQuery } from '@mui/material';

import ImageButton from '../ImageButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import WalletConnection from '../WalletConnection';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {PeraWalletConnect} from "@perawallet/connect"
import { disconnectAndBurn, setAddress } from '../../walletSlice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import LensIcon from '@mui/icons-material/Lens';
const peraWallet = new PeraWalletConnect();
const logo = 'https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/IMG_5433.png?alt=media&token=e98b2bb3-14f9-46d6-ac8f-344b081d0f10';

export default function Nav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const application = useSelector(state=>state.application);
  const wallet = useSelector(state=>state.wallet);

  const [walletAddress,setWalletAddress]=useState('');
  const [role,setRole]=useState('user');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [showWalletConnection,setShowWalletConnection] = useState(false);
const [anchor,setAnchor] = useState('left');
const [drawerOpen, setDrawerOpen] = useState(false);
useEffect(()=>{
  if(application.currentUser){
    setRole(application.currentUser.role);
  }
},[application.currentUser])
  useEffect(()=>{
    setWalletAddress(wallet.address);
    },[wallet.address]);

    useEffect(() => {
      console.log('walletProvider',wallet.walletProvider)
      if(wallet.walletProvider==="Pera" || wallet.walletProvider ===''){
        peraWallet
        .reconnectSession()
        .then((accounts) => {
          peraWallet.connector.on("disconnect", handleDisconnectWalletClick);
  
          if (accounts.length) {
            console.log(`reconnecting session: ${accounts[0]}`);
            dispatch(setAddress(accounts[0]));
          }
        })
        .catch((e) => console.log(e));
      }
    
    }, []);
const handleDisconnectWalletClick = ()=>{
  dispatch(disconnectAndBurn());
}


  return (
    <>  <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{pt:1}}>
        <Toolbar>
       
          <ImageButton onClick={()=>navigate('/')} imageSrc={logo} hoverImageSrc={logo} height={isMobile?60:80} width={'auto'} />
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
         <Typography fontWeight={'bold'} variant="h4" component="div" sx={{ flexGrow: 1 }}>
          
          </Typography>
          <Box display={'inline-flex'}>
          
          
      <IconButton size='medium' color={`${walletAddress.length>0?'primary':'secondary'}`}  onClick={()=>{
                                      console.log('wallet',walletAddress);
                                      if(walletAddress.length>1){
                                        navigate('/holders-wallet');
                                        return;
                                      }else{
                                        setShowWalletConnection(true);
                                      }
                                    }} >
        <AccountBalanceWalletIcon/>
       
       
      </IconButton>
    
          </Box>
         
        </Toolbar>
      </AppBar>
     
      <WalletConnection open={showWalletConnection} onClose={()=>{
      setShowWalletConnection(false);

      if(walletAddress.length>1){
              navigate('/holders-wallet');
        }
      }}/>
    </Box>
    <Drawer
            anchor={anchor}
            open={drawerOpen}
            onClose={()=>setDrawerOpen(!drawerOpen)}
          >
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={()=>setDrawerOpen(false)}
      
    >
      <Box sx={{
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center'
        }}>
      <ImageButton onClick={()=>navigate('/')} imageSrc={logo} hoverImageSrc={logo} height={'auto'} width={'100%'} />
      </Box>
      <List>
        {['About','Blog','Gen-1', 'Gen-2', 'Gen-1-Reboot','Clucker-Coops','Gen-3','Custom-Cluckers'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>navigate(text.toLowerCase())}>
              <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
         {role=="admin" && 
           <ListItem key="admin" disablePadding>
           <ListItemButton onClick={()=>navigate('/admin')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Admin</Typography>
           </ListItemButton>
         </ListItem>
        }
        {role=="admin" && 
           <ListItem key="key1" disablePadding>
           <ListItemButton onClick={()=>navigate('/manage-posts')}>
             <Typography sx={{ml:3}} color='text.primary' fontWeight='bold'>Manage Blog Posts</Typography>
           </ListItemButton>
         </ListItem>
        }
      </List>
      
    </Box>
    </Drawer>
    </>
  
  );
}
const { Typography, Box } = require("@mui/material")

const AlgoAddict420 = ()=>{
    return(
        <Box  sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
           
          }}>
        <Typography color="secondary" variant="body2"  onClick={()=>window.open("https://twitter.com/algoaddict420")}>
            Made by: @AlgoAddict420
        </Typography>
        </Box>
    )
}
export default AlgoAddict420;
import React, { useState, useEffect } from 'react';
import { TextField, Chip, Stack, Box, Grid } from '@mui/material';

function AddressInput({ title,addresses, setAddresses}) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      if (!addresses.includes(inputValue)) {
        const newAddresses = [...addresses, inputValue];
        setAddresses(newAddresses);  // Update addresses in parent component
        setInputValue('');
      }
    }
  };

  const handleDelete = (address)=>{
    console.log('delete',address);
    setAddresses(addresses.filter(o=>o!==address));
    }

  return (
    <Box>
      <TextField
        label={title}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        variant="outlined"
        fullWidth
        placeholder={title}
      />
   <Grid container spacing={1} sx={{ marginTop: 2 }}>
      {addresses&&  addresses.map((address, index) => (
        <Grid item key={index}>
          <Chip
            label={address}
            onDelete={()=>handleDelete(address)}
            color="primary"
          />
        </Grid>
      ))}
    </Grid>
    </Box>
  );
}

export default AddressInput;

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, Container, Paper, Typography } from '@mui/material';
import { registerWithEmailAndPassword } from '../../firebase';
import {  useNavigate } from 'react-router-dom';

const SignUpForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Here you can perform your form submission logic using Node.js backend
      registerWithEmailAndPassword(name,email,password).then(val=>{
alert(val);
navigate('/');
      }).catch(err=>setErrorMessage(err));
      // Reset the form after submission
      setName('');
      setEmail('');
      setPassword('');
      // Simulate an error response from the server
      throw new Error('Error occurred during sign up');
    } catch (error) {
      setErrorMessage(error.message);
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  return (
    <Container maxWidth='sm'>
   <Paper sx={{backgroundColor:'secondary' , padding:4, mt:5}}>
      <Box sx={{width:'100%',textAlign:'center' }}><Typography variant='h4' component='h4'>Sign Up!</Typography></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
       <TextField
        label="Confirm-Password"
        variant="outlined"
        type="confirm-password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary">
        Sign Up
      </Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
  <MuiAlert onClose={handleSnackbarClose} severity="error" elevation={6} variant="filled">
    {errorMessage}
  </MuiAlert>
</Snackbar>
    </form>
    </Paper>
    </Container>
 
  );
};

export default SignUpForm;
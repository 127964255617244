import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Carousel = ({ images, setImages }) => {
    const [uploading, setUploading] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const uploadImage = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const storage = getStorage();
        const storageRef = ref(storage, `carousel/${file.name}`);
        setUploading(true);

        try {
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            setImages([...images, url]);
        } catch (error) {
            console.error('Upload failed:', error);
        }
        setUploading(false);
    };

    const handleRemoveImage = (url) => {
        setImages(images.filter(img => img !== url));
    };

    return (
        <Box sx={{ margin: 'auto'}}>
            <Typography>Carousel</Typography>
            <Slider {...settings}>
                {images.map((url, index) => (
                    <div key={index}>
                        <img src={url} alt={`carousel-img-${index}`} style={{ maxHeight: '250px', height: 'auto' }} />
                        <IconButton onClick={() => handleRemoveImage(url)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </Slider>
            <Button variant="contained" component="label" startIcon={<PhotoCamera />} >
                <input type="file" hidden onChange={uploadImage} />
            </Button>
        </Box>
    );
};

export default Carousel;

import { Container } from "@mui/material"
import CollectionPage from "../CollectionPage"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../Utilities/nftUtil";
import algosdk from "algosdk";
import { fetchCreatedAssets, fetchOwnedAssets } from "../../walletSlice";
const ARC19_TEMPLATE = "template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}"

const port = "";
const indexer = `https://mainnet-idx.algonode.cloud/v2`;
var purestakeURL = `https://mainnet-algorand.api.purestake.io/ps2`;
const token = {
    'X-API-Key': process.env.REACT_APP_PURESTAKE_API,
  };
const indexerClient = new algosdk.Indexer(token,indexer,port);
const algodClient = new algosdk.Algodv2(token,purestakeURL,port);
const dec = new TextDecoder();

const MyCoops = ()=>{
    const dispatch = useDispatch();
    const application = useSelector(state=>state.application);
    const wallet = useSelector(state=>state.wallet);

    const [collection,setCollection] = useState([]);
    const [message,setMessage] = useState('');

    useEffect(()=>{
       if(application.collections && wallet.address){
        const fetchData  = async ()=>{
            try{
                var result =  await dispatch(fetchOwnedAssets(wallet.address));
                var assets = result.payload;
                console.log("assets",assets);
                return assets;
        
            }catch(error){
                console.error(error);
            }
       
        } 
     fetchData().then(async (data)=>{
        let output = [];
        console.log('collections',application.collections);

        let filter1 =application.collections?.filter(o=>o.unitName === 'CL');
      
        console.log('total Gen 1 Reboot assets:',filter1);
        for (let index = 0; index < filter1.length; index++) {
            const element = filter1[index];
            const xr = data.filter(i=>i['asset-id']===element.assetId);

            if(xr.length>0){
                console.log('xr',xr[0]);

                if(xr[0].amount>0){
                    output.push(element);
                }
            }
        }
        console.log('output',output)
        setCollection(output);
     }).catch(err=>console.error(err));
   

    }
    },[application.collections,wallet.address]);
 
        
      
    return (
        <Container maxWidth='lg'>
            <CollectionPage title={'My Coops'} collection={collection??[]} emptyMessage={'You dont own any Coops'} showUpgrade={true}/>
        </Container>
    )
}
export default MyCoops
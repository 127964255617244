import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    authenticating:false,
    currentUser:{},
    nftCollections:[],
    nftCollectionPages:[],
    currentCreatorAssets:[],
    collections:[],
    currentNFT:{},
    pinningImage:false,
    pinningJSON:false,
    selectedCoop:{},
    selectedWorkOrder:{},
    blogPosts:[],
    selectedPost:{},
}
const applicationSlice = createSlice({
    name:'application',
    initialState:initialState,
    reducers: {
        login (state) {
            state.authenticating=true;
         },
         setSigningUp (state,action){
            state.signingUp = action.payload;
         },
         setSignUpError (state,action){
            state.signUpError = action.payload;
         },
         setCurrentUser (state,action){
            state.currentUser = action.payload;
         },
         logoff(state){
            state.auth = {};
         },
         setCurrentCreatorAssets(state,action){
            state.currentCreatorAssets=action.payload;
         },
         setAddAssetToCreatorsAssets(state,action){
            let temp =state.currentCreatorAssets;
            temp.push(action.payload);
            state.currentCreatorAssets=temp;
         },
         setCollections(state,action){
            state.collections = action.payload;
         },
         setCurrentNFT(state,action){
            state.currentNFT = action.payload;
         },    
         setPinningImage(state,action){
            state.pinningImage = action.payload;
         },
         setPinningJSON(state,action){
            state.pinningJSON = action.payload;
         },
         setSelectedCoop(state,action){
            state.selectedCoop = action.payload;
         },
         setSelectedWorkOrder(state,action){
            state.selectedWorkOrder = action.payload;
         },
         setNFTCollections(state,action) {
            state.nftCollections = action.payload;
         },
         upsertNFTCollections(state,action){
            const newItems = action.payload; // Expecting an array of items

            newItems.forEach(newItem => {
                const existingItemIndex = state.nftCollections.findIndex(item => item.id === newItem.id);
                if (existingItemIndex !== -1) {
                    // Item exists, update it
                    state.nftCollections[existingItemIndex] = newItem;
                } else {
                    // Item does not exist, add it
                    state.nftCollections.push(newItem);
                }
            });
         },
         setNFTCollectionPages(state,action) {
            state.nftCollectionPages = action.payload;
         },
         upsertNFTCollectionPages(state,action){
            const newItems = action.payload; // Expecting an array of items

            newItems?.forEach(newItem => {
                const existingItemIndex = state.nftCollectionPages.findIndex(item => item.id === newItem.id);
                if (existingItemIndex !== -1) {
                    // Item exists, update it
                    state.nftCollectionPages[existingItemIndex] = newItem;
                } else {
                    // Item does not exist, add it
                    state.nftCollectionPages.push(newItem);
                }
            });
         },
         setBlogPosts(state,action){
            state.blogPosts = action.payload;
         },
         setSelectedPost(state,action){
            state.selectedPost = action.payload;
         }
    },
      extraReducers(builder) {
        
      }
})



export const {
   login,
   setSigningUp,
   setSignUpError,
   setUID,
   setConfiguration,
   logoff,
   setCurrentUser,
   setAddAssetToCreatorsAssets,
   setCollections,
   setCurrentNFT,
   setPinningImage,
   setPinningJSON,
   setSelectedCoop,
   setSelectedWorkOrder,
   setCurrentCreatorAssets,
   setNFTCollections,
   setNFTCollectionPages,
   upsertNFTCollections,
   upsertNFTCollectionPages,
   setBlogPosts,
   setSelectedPost
} = applicationSlice.actions
export default applicationSlice.reducer




import { createAsyncThunk,  createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import axios from "axios"


const initialState = {
    status:'',
    error:'',
    walletProvider:'',
    address:'',
    holdersNFTs: [],
    loading:false,
}


const walletSlice = createSlice({
    name:'wallet',
    initialState:initialState,
    reducers: {
        
        setHoldersNfts(state, action) {
          state.holdersNFTs = action.payload;
        },
        addNFT(state, action) {
          let list = state.holdersNFTs;
          console.log("list",list);
          if(!list){list = [];}
          state.holdersNFTs = list.push(action.payload);
        },
        setWalletProvider(state,action){
          state.walletProvider=action.payload;
        },
        setAddress(state,action){
          state.address = action.payload;
        },
        disconnectAndBurn: (state) => ({ ...initialState, state }),
        setLoading(state,action){
          state.loading=action.payload;
        },
      },
      extraReducers(builder) {
        builder
          .addCase(fetchCreatedAssets.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchCreatedAssets.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            if(state.accounts)
            state.accounts = state.accounts.concat(action.payload)
          })
          .addCase(fetchCreatedAssets.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
         
      }
})




export const {switchChain, reset, setWalletProvider,setAddress,setNfts,disconnectAndBurn,addBilly,setLoading,setDisconnect} = walletSlice.actions
export default walletSlice.reducer
export const selectAccount = state=>state.account;
export const selectStatus = state=>state.status;
export const fetchCreatedAssets = createAsyncThunk('accounts/getCreatedAssets', async (address) => {
    try {
      console.log("address:",address);
      if(address && address.length > 3){
        const res =await handleSearch(address);
         console.log(res);
      return res['created-assets'];
      }
      return isRejectedWithValue('wallet address was missing or invalid')

    } catch (error) {
        // You can choose to use the message attached to err or write a custom error
      return isRejectedWithValue('Opps there seems to be an error:'+error)
    }
 
});

export const fetchOwnedAssets = createAsyncThunk('accounts/getOwnedAssets', async (address) => {
  try {
    console.log("address:",address);
    if(address && address.length > 3){
      const res = await axios.get(`https://mainnet-idx.algonode.cloud/v2/accounts/${address}?exclude=created-apps`);
    const account = res.data.account;
    return account['assets'];
    }
    return isRejectedWithValue('wallet address was missing or invalid')

  } catch (error) {
      // You can choose to use the message attached to err or write a custom error
    return isRejectedWithValue('Opps there seems to be an error:'+error)
  }

});

export const fetchAccount = createAsyncThunk('accounts/getAccount', async (address) => {
  try {
    console.log("address:",address);
    if(address && address.length > 3){
      const res = await axios.get(`https://mainnet-idx.algonode.cloud/v2/accounts/${address}?exclude=created-apps`);
    const account = res.data.account;
    return account;
    }
    return isRejectedWithValue('wallet address was missing or invalid')

  } catch (error) {
      // You can choose to use the message attached to err or write a custom error
    return isRejectedWithValue('Opps there seems to be an error:'+error)
  }

});



const handleSearch = async (address) => {
  try {
    const response = await axios.get(`https://getaccountinfo-akdembq2qq-uc.a.run.app/?address=${address}`);
    const account = response.data.account;
    return account

  } catch (error) {
    console.error(error);
    return [];
  }
};









import algosdk from 'algosdk';

const port = "";
const token = ""; 
const indexer = `https://mainnet-idx.algonode.cloud`;
const algoNode = `https://mainnet-api.algonode.cloud`;
const algoClient = new algosdk.Algodv2(token, algoNode, port);
const indexerClient = new algosdk.Indexer(token, indexer, port);

// Make sure to export this client or pass it correctly to your component
export { algoClient, indexerClient };
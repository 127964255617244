import { Button, Card, CardActionArea, CardActions, CardContent, Container, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LoadableImage } from "../LoadableImage";

export const About =()=>{
    const navigate = useNavigate();
    return(
        <Container maxWidth='md'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component={'h4'} variant="h4">About Cluckers</Typography>
                </Grid>
                <Grid item  xs={12} >
                 <Card >
                    <CardActionArea>
                    <CardContent>
                        
                        <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/bafybeigqvuo5j6wjvjlzmpbxmfvbeeztv5vmgemw5rlmaseh6pmgy6veim#i'} maxHeight={300}  />
                        <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                        Gen 1
                        </Typography>
                        <Typography  component={'p'} variant="body2" color={'text.secondary'}>
                         A very limited collection (10 Regular with 2 Customs) that I made and was bought by SmirkRabbitCrew, CJTheGreat and GOATish.
                        </Typography>
                         <Typography component={'p'} variant="body2" color={'text.secondary'}>
                             I enjoyed creating them and had learned more and wanted to do a more detailed Clucker collection.
                          </Typography>
                      </CardContent>
                      </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/gen-1')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                    </Card>

                </Grid>
               
                <Grid item  xs={12} >
                 <Card >
                    <CardActionArea>
                    <CardContent>
                        <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/bafkreibfruddr3juakgp63epdfdmxqkktya4xy2tzxoiy5tune57midmpm#i'}  maxHeight={300} />
                        <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                            Gen 2
                        </Typography>
                        <Typography component={'p'} variant="body1" color={'text.secondary'}>
                           50 1/1 NFTs that started out as regular still images and became animated gifs as the collection went along and my skills improved.
                        </Typography>
                    </CardContent>
                    </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/gen-2')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                 </Card>
                </Grid>
              
                <Grid item  xs={12} >
                <Card >
                    <CardActionArea>
                    <CardContent>
                      <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/QmaUznAgDvjSgtJYTU31eof9y1BykJm8cfXhqnt5EDNkMS/'} maxHeight={300} />
                      <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                            Gen 1 Reboot
                        </Typography>
                      <Typography component={'p'} variant="body1" color={'text.secondary'}>
                    ARC19 (upgradable) Collection of 200 Cluckers made in the style of Gen 1 but higher pixel count/resolution with added utilities and rewards.  
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                       Reboot Cluckers are able to be "stacked", traits from one Clucker you own can be taken and put on another Clucker you own.
                       </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                       This does have a cost.. 2 $Algo or 5 $Coop and the Clucker who had its traits taken the remaining traits will be burned (i.e. sent back and destroyed). The upgraded NFT will now have a rewards multiplier so that as far as rewards are concerned you wont reduce the amount received. 
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                        Since there are always 200 in the collection, a new Clucker with a different series of traits will be Created in the place of the burned NFT.
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                        This way holders decide when a new variant is created and potentially when the holder base should increase. 
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                        The Reincarnated Clucker will be either Rumbled for in Discord or listed at auction to raise funds for the community vault.
                    </Typography>
                    </CardContent>
                    </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/gen-1-reboot-')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                 </Card>
                </Grid>
                <Grid item  xs={12} >
                 <Card >
                    <CardActionArea>
                    <CardContent>
                        
                        <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/QmQEUKHvaXeWM3X43QB8DJY28Mxqu8THveL7fpGCqUbdkP'} maxHeight={300}  />
                        <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                        Gen 3
                        </Typography>
                        <Typography  component={'p'} variant="body2" color={'text.secondary'}>
                         The Third Generation of Algo Cluckers! 350 1/1 pieces made by AlgoAddict420 in collaboration with SmirkRabbitCrew.
                         All backgrounds were made by SmirkRabbitCrew, all save one rare Guest Artist: Algolem
                        </Typography>
                        
                      </CardContent>
                      </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/gen-3')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                    </Card>

                </Grid>
                <Grid item xs={12} >
                <Card >
                    <CardActionArea>
                    <CardContent>
                     <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/QmYvVqvJajHsg8PQ6hVbLMGFUJt5USmLb3Kc2ctT8YajCX/'} maxHeight={300} />
                     <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                     Clucker Coops
                        </Typography>
                     <Typography component={'p'} variant="body1" color={'text.secondary'}>
                        Coops (Chicken Houses) you help build for your Cluckers! Initial Mint of 100 Lots of virtual land in Cluckerville.
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                     Land owners get to decide how the lot is used. Default build (10 $Coop) or Custom build (20 $Coop) to get started and then upgrades start at 5 $Coop and go up depending on level of difficulty.
                    </Typography>
                    <Typography component={'p'} variant="body1" color={'text.secondary'}>
                        This is all for fun and is an outlet for artistic growth and community engagement.
                     </Typography>
                     </CardContent>
                     </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/clucker-coops')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                     </Card>

                </Grid>
             
                <Grid item xs={12} >
                <Card >
                    <CardActionArea>
                    <CardContent>
                        <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/Qmb3AZCn7VPpVpkftekAe3shJrmuqDfHxKedSKQsbLdW5M/'} maxHeight={300} />
                        <Typography component={'h5'} variant='h5' color="text.primary" gutterBottom>
                             Custom Cluckers
                        </Typography>
                        <Typography component={'p'} variant="body1" color={'text.secondary'}>
                             Any custom pieces I do for holders that dont belong to a specific collection end up here.
                              </Typography>                    
                              <Typography component={'p'} variant="body1" color={'text.secondary'}>
                                    Thank you for taking time to check out the project and I hope you have a great day!
                              </Typography>   
                      </CardContent>
                      </CardActionArea>
                      <CardActions>
        <Button onClick={()=>navigate('/custom-cluckers')} size="small" color="primary">
          View Collection
        </Button>
      </CardActions>
                </Card>
                </Grid>
               
            </Grid>
        </Container>
    )
}
import { Autocomplete, Button, Container, Grid, Select, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { LoadableImage } from "../LoadableImage";
import { SliderPicker  } from 'react-color'
import { updateDocument } from "../../firebase";
import { useNavigate } from "react-router-dom";

const constructionTypes = [
    { label: 'NEW CONSTRUCTION', value: 'NEW CONSTRUCTION', coopCost:10 },
    { label: 'SMALL MODIFICATION', value: 'SMALL MODIFICATION',  coopCost:2},
    { label: 'UPGRADE COOP', value: 'UPGRADE COOP', coopCost:5 },
    { label: 'DEMOLISH COOP', value: 'DEMOLISH COOP', coopCost:10 },
  ];

const coopStyles = [
    { label: 'DEFAULT', value: 'DEFAULT' },
    { label: 'CUSTOM', value: 'CUSTOM' },
  ];

const CreateWorkOrder = ()=> 
{
    const navigate = useNavigate();
    const application = useSelector((state)=>state.application);
    const wallet = useSelector((state)=>state.wallet);

    const [coop,setCoop] = useState(0);
    const [description,setDescription] = useState('');
    const [coopExterior,setCoopExterior] = useState('');
    const [coopDoor,setCoopDoor] = useState('');
    const [coopTrim,setCoopTrim] = useState('');
    const [workType,setWorkType] = useState('');
    const [coopType,setCoopType] = useState('');
    const [charges,setCharges] = useState([]);




   
    useEffect(() => {
      if(application.selectedCoop)
        {
            setCoop(application.selectedCoop);
        }
      return () => {
        
      }
    }, [application.selectedCoop]);

    const handleRequest =(e)=>{
        
      
        console.log(result);
        var date = new Date(Date.now());
        var totalSeconds = Math.floor(date.getTime() / 1000);
        var id = `${wallet.address.substring(0,5)}-${coop.assetId}-${totalSeconds}`;
        const result = {
            id:id,
            address:wallet.address,
            workType:workType,
            coopType:coopType,
            assetId:coop.assetId,
            description:description,
            coopExterior:coopExterior,
            coopDoor:coopDoor,
            coopTrim:coopTrim,
            cost:charges,
        }
        updateDocument('work-orders',id,result,false).then(res=>{
            console.log('Work Order Save Result',res);
            navigate('/my-coops');
        }).catch((err)=>alert(err));
    }
    return (
        <Container maxWidth='md'>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Typography component='h4' variant='h4'>Construction Request</Typography>
                <Typography component='h6' variant='h6'>ALPHA VERSION (its....not ready, but look!)</Typography>

                </Grid>
                <Grid item xs={12}>
                    <LoadableImage imageUrl={coop.image} maxHeight={500}/>
                </Grid>
                <Grid item  xs={12} md={4}>
                    <Autocomplete
                         disablePortal
                         id="work-type"
                         options={constructionTypes}
                        getOptionLabel={(option)=>option.label}
                        onChange={(e,newValue)=>setCoopType(newValue)}
                         renderInput={(params) => <TextField {...params} label="Work Type" />}
                    />
                </Grid>
                <Grid item  xs={12} md={4}>
                <Autocomplete
                         disablePortal
                         id="coop-type"
                         options={coopStyles}
                         renderInput={(params) => <TextField {...params} label="Coop Type" />}
                    />
                </Grid>
                <Grid item xs={12}>
                <TextField label="Please detail your Clucker Coop work request."
                            multiline
                            rows={4}
                            onChange={(e)=>setDescription(e.target.value)}
                            value={description}
                            variant="outlined"
                            fullWidth
    />
</Grid>


<Grid item xs={12} md={4}>
<Stack direction={'column'} spacing={2}>
    <TextField label="Exterior Color"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={coopExterior}
    />
    <SliderPicker  color={ coopExterior }
        onChangeComplete={(o)=>setCoopExterior(o.hex)}/>
        <Button  onClick={()=>setCoopExterior('')}>Clear</Button>
</Stack>
</Grid>
<Grid item xs={12} md={4}>
<Stack direction={'column'} spacing={2}>
    <TextField label="Door Color"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={coopDoor}
    />
    <SliderPicker  color={ coopDoor }
        onChangeComplete={(o)=>setCoopDoor(o.hex)}/>
        <Button onClick={()=>setCoopDoor('')}>Clear</Button>

</Stack>
</Grid>
<Grid item xs={12} md={4}>
    <Stack direction={'column'} spacing={2}>
    <TextField label="Trim/Accent Color"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={coopTrim}
    />
    <SliderPicker  color={ coopTrim }
        onChangeComplete={(o)=>setCoopTrim(o.hex) }/>
        <Button onClick={()=>setCoopTrim('')}>Clear</Button>

    </Stack>

</Grid>
<Grid item xs={12}>
    <Button onClick={handleRequest}>Submit</Button>
</Grid>
            </Grid>
        </Container>
    )
}
export default CreateWorkOrder